// NotFoundPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f8f8;
`;

const NotFoundTitle = styled.h1`
  font-size: 6rem;
  color: #ff4d4d;
  margin-bottom: 1rem;
`;

const NotFoundMessage = styled.p`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 2rem;
`;

const GoBackLink = styled(Link)`
  font-size: 1.2rem;
  color: #fff;
  background-color: #007bff;
  padding: 1rem 2rem;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const NotFoundPage = () => {
  return (
    <NotFoundContainer>
      <NotFoundTitle>404</NotFoundTitle>
      <NotFoundMessage>Oops! The page you are looking for does not exist.</NotFoundMessage>
      <GoBackLink to="/">Go back to the dashboard</GoBackLink>
    </NotFoundContainer>
  );
};

export default NotFoundPage;