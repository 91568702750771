

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, useMediaQuery, useTheme, Divider, Box, Typography, Avatar } from '@mui/material';
import { Home, Business, Settings, Task, AccountBalance, RateReview, CalendarMonth, Analytics, Build, ExitToApp,SettingsApplications } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import UserContext from '../UserContext';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';

const Sidebar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user, isSidebarOpen, handleCloseSidebar,isAdmin } = useContext(UserContext);



  const adminSidebarItems = [
    { text: 'Listings', icon: <Home />, link: '/listings' },
    // { text: 'Portfolio', icon: <Business />, link: '/portfolio' },
    // { text: 'Operations', icon: <Settings />, link: '/operations' },
    // { text: 'Tasks', icon: <Task />, link: '/tasks' },
    { text: 'Accounting', icon: <AccountBalance />, link: '/accountant' },
    { text: 'Reviews', icon: <RateReview />, link: '/reviews' },
    // { text: 'Multi calendar', icon: <CalendarMonth />, link: '/calendar' },
    { text: 'Payments', icon: <Analytics />, link: '/allreservations' },
    { text: 'Settings', icon: <SettingsApplications />, link: '/settings' },
    // { text: 'Dev Tools', icon: <Build />, link: '/dev-tools' },
  ];

  const userSidebarItems = [
    { text: 'Listings', icon: <Home />, link: '/listings' },
    // { text: 'Portfolio', icon: <Business />, link: '/portfolio' },
    // { text: 'Multi calendar', icon: <CalendarMonth />, link: '/calendar' },
  ];

//   const sidebarItems = user.isAdmin ? adminSidebarItems : userSidebarItems;
  const sidebarItems = user && isAdmin ? adminSidebarItems : userSidebarItems;


  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Optionally, you can redirect to the login page after successful logout
      window.location.href = '/login';
    } catch (error) {
      console.log('Error logging out:', error);
    }
  };


  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'persistent'}
      anchor="left"
      open={isSidebarOpen}
      onClose={handleCloseSidebar}
      sx={{
        width: isSidebarOpen ? 240 : 0,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 240,
          boxSizing: 'border-box',
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: theme.spacing(0, 1) }}>
        <IconButton onClick={handleCloseSidebar}>
          <ChevronLeftIcon />
        </IconButton>
      </Box>
      <Divider />
      <List>
        {sidebarItems.map((item, index) => (
            <ListItem button key={index} component={Link} to={item.link}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
            </ListItem>
        ))}
        </List>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ display: 'flex', alignItems: 'center', padding: theme.spacing(1) }}>
        <Avatar src={user.avatar} alt={user.firstName} />
        <Box sx={{ marginLeft: 1 }}>
          <Typography variant="subtitle1">{user.firstName}</Typography>
          <Typography variant="body2">{user.email}</Typography>
        </Box>
        <IconButton sx={{ marginLeft: 'auto' }} onClick={handleLogout}>
            <ExitToApp />
            </IconButton>
      </Box>
    </Drawer>
  );


};

export default Sidebar;