

import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Box, Grid, Paper, Tab, Tabs, useMediaQuery, useTheme, Typography } from '@mui/material';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import DetailedPropertyComponent from '../components/Property_tabs/DetailedPropertyComponent';
import api from '../api';
import UserContext from '../UserContext';
import PhotoGallery from '../components/Property_tabs/PhotoGallery';
import FinancePage from '../components/Property_tabs/Finance';
import ReviewsPage from '../components/Property_tabs/ReviewsPage';
import ReservationTable from '../components/Property_tabs/ReservationTable';
import LoadingScreen from '../components/LoadingScreen';
import PropertyAmenities from '../components/Property_tabs/AmenitiesTab';


const DetailedPropertyPage = () => {
  const { id } = useParams();
  const [property, setProperty] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [error, setError] = useState(null);
  const [events, setEvents] = useState([]);
  const [reservations, setReservations] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const { user } = useContext(UserContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const userId = user.id;
    const fetchProperty = async () => {
      try {
        
        const response = await api.get(`/api/listings/${id}`, {
          params: { userId },
        });
        setProperty(response.data);
      } catch (error) {
        console.error('Error fetching property:', error);
      }
    };

    const fetchReviews = async () => {
        try {
          const response1 = await api.get(`/api/reviews/${id}`, {
            params: {
              userId,
            },
          });
          console.log("hel")
          console.log(response1.data)
          setReviews(response1.data);
          
        } catch (error) {
          console.error('Error fetching reviews:', error);
          
          
        }
      };



    const fetchReservationData = async () => {
      try {
        console.log('Fetching reservations for property:', id);
        const response = await api.get(`/api/reservations/${id}`, {
          params: {
            userId: user.id,
          },
        });
    
        if (response.status === 200 && response.data) {
          const reservationsData = response.data;
          console.log("Raw reservations data:", reservationsData);
    
          // Convert the reservations object to an array if it's not already
          const reservationsArray = Array.isArray(reservationsData) 
            ? reservationsData 
            : Object.values(reservationsData);
    
          console.log("Processed reservations array:", reservationsArray);
    
          // Filter out any null or undefined values
          const validReservations = reservationsArray.filter(reservation => reservation != null);
    
          console.log("Valid reservations:", validReservations);
          setReservations(validReservations);
          return validReservations;
        } else {
          console.error('Unexpected reservation data format:', response.data);
          setReservations([]);
          return [];
        }
      } catch (error) {
        console.error('Error fetching reservation data:', error);
        setReservations([]);
        return [];
      }
    };
    const fetchCalendarData = async (reservationsArray) => {
      try {
        const response = await api.get(`/api/calendar/${id}`, {
          params: { userId: user.id },
        });
    
        if (response.status === 200 && response.data) {
          const calendarData = response.data;
          console.log("CALENDAR DATA", calendarData);
    
          if (!reservationsArray || reservationsArray.length === 0) {
            console.warn('No reservations data found');
            setEvents([]);
            return;
          }
    
          // Filter out cancelled reservations for calendar events
          const activeReservations = reservationsArray.filter(reservation => reservation.status !== 'canceled' && reservation.status !== 'inquiry');
    
          // Filter cancelled_but_paid reservations
          const cancelledButPaidReservations = reservationsArray.filter(reservation =>
            reservation.status === 'canceled' &&
            reservation.customFields &&
            reservation.customFields.some(field => field.fieldId === '5bcb9c8e4ffd0c00388f5641')
          );
    
          // Create reservation events
          const reservationEvents = activeReservations.map(reservation => {
            const checkInDate = new Date(reservation.checkInDateLocalized);
            const checkOutDate = new Date(reservation.checkOutDateLocalized);
            
            // Set the end time to 10 AM on the checkout day
            checkOutDate.setHours(10, 0, 0, 0);
    
            return {
              id: reservation._id,
              classNames: "half-day-end",
              resourceId: reservation._id,
              start: checkInDate,
              end: checkOutDate,
              display: 'block',
              backgroundColor: 'green',
              borderColor: 'green',
              title: reservation.guest?.fullName || 'Reservation',
              extendedProps: {
                reservationId: reservation._id,
                reservation: reservation,
                source: reservation.source
              },
            };
          });
    
          // Create cancelled_but_paid reservation events
          const cancelledButPaidEvents = cancelledButPaidReservations.map(reservation => {
            const checkInDate = new Date(reservation.checkInDateLocalized);
            const checkOutDate = new Date(reservation.checkOutDateLocalized);
            
            // Set the end time to 10 AM on the checkout day
            checkOutDate.setHours(10, 0, 0, 0);
    
            return {
              id: reservation._id,
              classNames: "half-day-end",
              resourceId: reservation._id,
              start: checkInDate,
              end: checkOutDate,
              display: 'block',
              backgroundColor: 'green', // Full background color red
              borderColor: 'green',
              title: reservation.guest?.fullName || 'Cancelled ',
              extendedProps: {
                reservationId: reservation._id,
                reservation: reservation,
                source: reservation.source
              },
            };
          });
    
          const availabilityEvents = calendarData.map(day => ({
            start: day.date,
            end: day.date,
            title: `€${day.price}`,
            display: 'background',
            textColor: 'black',
            backgroundColor: day.status === 'unavailable' ? '#818894' : 'transparent',
          }));
    
          setEvents([...reservationEvents, ...cancelledButPaidEvents, ...availabilityEvents]);
        } else {
          console.error('Unexpected calendar data format:', response.data);
          setEvents([]);
        }
      } catch (error) {
        console.error('Error fetching calendar data:', error);
        setEvents([]);
      }
    };
    

    const fetchData = async () => {
  
      const propertyArr = await fetchProperty();

      const reviewsArr = await fetchReviews();
      const reservationsArray = await fetchReservationData();
      console.log('Reservations array after fetch:', reservationsArray); // Debugging
      if (reservationsArray.length > 0) {
        await fetchCalendarData(reservationsArray);
      } else {
        setEvents([ [], []]);
        console.error('No reservations data found');
      }
    };


    fetchData();
  }, [id, user.id]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (!property) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <Header toggleSidebar={toggleSidebar} />
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <Container maxWidth={false} sx={{ mt: 4, mb: 4, px: isSmallScreen ? 2 : 4 }}>
        <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row' }}>
          {!isSmallScreen && (
            <Box sx={{ width: '15%', mr: 3 }}>
              <Paper>
                <Tabs
                  orientation="vertical"
                  value={activeTab}
                  onChange={handleTabChange}
                  sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                  <Tab label="Overview" sx={{ textTransform: 'none', fontSize: '0.9rem', py: 1 }} />
                  <Tab label="Pricing" sx={{ textTransform: 'none', fontSize: '0.9rem', py: 1 }} />
                  <Tab label="Reservations" sx={{ textTransform: 'none', fontSize: '0.9rem', py: 1 }} />
                  <Tab label="Photos" sx={{ textTransform: 'none', fontSize: '0.9rem', py: 1 }} />
                  {/* <Tab label="Amenities" sx={{ textTransform: 'none', fontSize: '0.9rem', py: 1 }} /> */}
                  <Tab label="Reviews" sx={{ textTransform: 'none', fontSize: '0.9rem', py: 1 }} />
                </Tabs>
              </Paper>
            </Box>
          )}
          <Box sx={{ flexGrow: 1 }}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h5" sx={{ mb: 2 }}>
                {property.name}
              </Typography>
              {activeTab === 0 && <DetailedPropertyComponent property={property} events={events} reservations={reservations} />}
              {activeTab === 1 && <FinancePage property={property} />}
              {activeTab === 2 && <ReservationTable reservations={reservations} />}
              {activeTab === 3 && <PhotoGallery pictures={property.pictures} />}
              {/* {activeTab === 4 && <PropertyAmenities property={property}/>} */}
              {activeTab === 4 && <ReviewsPage reviewsData={reviews} />}
            </Paper>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default DetailedPropertyPage;