// // import React, { useState } from 'react';
// // import { Box, Card, CardContent, Container, Table, TableBody,TextField, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

// // import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// // import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// // const ReservationTable = ({reservations}) => {

// //     const [selectedDates, setSelectedDates] = useState({
// //         checkIn: null,
// //         checkOut: null,
// //         });

// //         // Handle date change
// //         const handleDateChange = (field, date) => {
// //         setSelectedDates((prevDates) => ({
// //             ...prevDates,
// //             [field]: date,
// //         }));
// //         };

// //         // Filter reservations based on selected date range
       
// //         const filteredReservations =reservations ?  reservations.filter((reservation) => {
// //         const checkInDate = new Date(reservation.checkIn);
// //         const checkOutDate = new Date(reservation.checkOut);
// //         const selectedCheckIn = selectedDates.checkIn;
// //         const selectedCheckOut = selectedDates.checkOut;

// //         return (
// //             (!selectedCheckIn || checkInDate >= selectedCheckIn) &&
// //             (!selectedCheckOut || checkOutDate <= selectedCheckOut)
// //         );
// //         }) : [];


// //         // Calculate total accommodation, cleaning, and management fees for filtered reservations
// //         const totalAccommodation = filteredReservations.reduce((sum, reservation) => sum + reservation.money.fareAccommodation, 0);
// //         const totalCleaning = filteredReservations.reduce((sum, reservation) => sum + reservation.money.fareCleaning, 0);
// //         const totalManagement = filteredReservations.reduce((sum, reservation) => sum + reservation.money.hostServiceFee, 0);


// // //   const totalAccommodation = reservations.reduce((sum, reservation) => sum + reservation.money.fareAccommodation, 0);
// // //   const totalCleaning = reservations.reduce((sum, reservation) => sum + reservation.money.fareCleaning, 0);
// // //   const totalManagement = reservations.reduce((sum, reservation) => sum + reservation.money.hostServiceFee, 0);
  

// //     return (
// //         <Container>
// //             <LocalizationProvider dateAdapter={AdapterDayjs}>
// //             <Box display="flex" justifyContent="space-between" mb={4}>
// //                 <DatePicker
// //                 label="Check-in"
// //                 value={selectedDates.checkIn}
// //                 onChange={(date) => handleDateChange('checkIn', date)}
// //                 renderInput={(params) => <TextField {...params} />}
// //                 />
// //                 <DatePicker
// //                 label="Check-out"
// //                 value={selectedDates.checkOut}
// //                 onChange={(date) => handleDateChange('checkOut', date)}
// //                 renderInput={(params) => <TextField {...params} />}
// //                 />
// //             </Box>
// //             </LocalizationProvider>
// //         <Box display="flex" justifyContent="space-between" mb={4}>
// //             <Typography variant="body1">Check-in from 2023-08-01</Typography>
// //             <Typography variant="body1">Check-in to 2024-05-01</Typography>
// //         </Box>

// //         <Box display="flex" justifyContent="space-between" mb={4}>
// //             <Card sx={{ backgroundColor: '#f0f8ff' }}>
// //             <CardContent>
// //                 <Typography variant="h6">Accommodation</Typography>
// //                 <Typography variant="h4">€{totalAccommodation.toFixed(2)}</Typography>
// //             </CardContent>
// //             </Card>
// //             <Card sx={{ backgroundColor: '#f0fff0' }}>
// //             <CardContent>
// //                 <Typography variant="h6">Cleaning</Typography>
// //                 <Typography variant="h4">€{totalCleaning.toFixed(2)}</Typography>
// //             </CardContent>
// //             </Card>
// //             <Card sx={{ backgroundColor: '#fff0f0' }}>
// //             <CardContent>
// //                 <Typography variant="h6">Management</Typography>
// //                 <Typography variant="h4">€{totalManagement.toFixed(2)}</Typography>
// //             </CardContent>
// //             </Card>
// //         </Box>

// //         <TableContainer>
// //             <Table>
// //             <TableHead>
// //                 <TableRow>
// //                 <TableCell>Confirmation Code</TableCell>
// //                 <TableCell>Actions</TableCell>
// //                 <TableCell>CheckIn Date</TableCell>
// //                 <TableCell>Checkout Date</TableCell>
// //                 <TableCell>Guest Name</TableCell>
// //                 <TableCell>Owner Paid At</TableCell>
// //                 <TableCell>Status</TableCell>
// //                 <TableCell>Balance</TableCell>
// //                 </TableRow>
// //             </TableHead>
// //             <TableBody>
// //                 {filteredReservations.map((reservation) => (
// //                 <TableRow key={reservation._id}>
// //                     <TableCell>{reservation.confirmationCode}</TableCell>
// //                     <TableCell>{reservation.checkInDateLocalized}</TableCell>
// //                     <TableCell>{reservation.checkOutDateLocalized}</TableCell>
// //                     <TableCell>{reservation.guest.fullName}</TableCell>
// //                     <TableCell>{reservation.listing.nickname }</TableCell>
// //                     <TableCell>{reservation.checkInDateLocalized}</TableCell>
// //                     <TableCell>{reservation.status}</TableCell>
// //                     <TableCell>{reservation.money.balanceDue}</TableCell>
// //                 </TableRow>
// //                 ))}
// //             </TableBody>
// //             </Table>
// //         </TableContainer>
// //         </Container>
// //     );
// //     };

// // export default ReservationTable;

// import React, { useState } from 'react';
// import { Box, Card, CardContent, Container, Table, TableBody, TextField, TableCell, TableContainer, TableHead, TableRow, Typography, Button } from '@mui/material';
// import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// const ReservationTable = ({ reservations }) => {
//   const [selectedDates, setSelectedDates] = useState({
//     checkIn: null,
//     checkOut: null,
//   });

//   const handleDateChange = (field, date) => {
//     setSelectedDates((prevDates) => ({
//       ...prevDates,
//       [field]: date,
//     }));
//   };

//   const filteredReservations = React.useMemo(() => {
//     if (!Array.isArray(reservations)) return [];

//     return reservations.filter((reservation) => {
//       try {
//         const checkInDate = new Date(reservation.checkIn);
//         const checkOutDate = new Date(reservation.checkOut);
//         const selectedCheckIn = selectedDates.checkIn ? new Date(selectedDates.checkIn) : null;
//         const selectedCheckOut = selectedDates.checkOut ? new Date(selectedDates.checkOut) : null;

//         return (
//           (!selectedCheckIn || checkInDate >= selectedCheckIn) &&
//           (!selectedCheckOut || checkOutDate <= selectedCheckOut)
//         );
//       } catch (error) {
//         console.error('Error filtering reservation:', error);
//         return false;
//       }
//     });
//   }, [reservations, selectedDates]);

//   const calculateTotal = (key) => {
//     try {
//       return filteredReservations.reduce((sum, reservation) => {
//         const value = reservation.money?.[key] || 0;
//         return sum + (typeof value === 'number' ? value : 0);
//       }, 0);
//     } catch (error) {
//       console.error(`Error calculating total for ${key}:`, error);
//       return 0;
//     }
//   };

//   const totalAccommodation = calculateTotal('fareAccommodation');
//   const totalCleaning = calculateTotal('fareCleaning');
//   const totalManagement = calculateTotal('hostServiceFee');

//   const downloadCSV = () => {
//     const headers = [
//       'Confirmation Code',
//       'CheckIn Date',
//       'Checkout Date',
//       'Guest Name',
//       'Owner Paid At',
//       'Status',
//     ];

//     const csvData = filteredReservations.map((reservation) => [
//       reservation.confirmationCode || '',
//       reservation.checkInDateLocalized || '',
//       reservation.checkOutDateLocalized || '',
//       reservation.guest?.fullName || '',
//       reservation.listing?.nickname || '',
//       reservation.status || ''
//     ]);

//     const csvContent = [
//       headers.join(','),
//       ...csvData.map((row) => row.join(',')),
//     ].join('\n');

//     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
//     const link = document.createElement('a');
//     if (link.download !== undefined) {
//       const url = URL.createObjectURL(blob);
//       link.setAttribute('href', url);
//       link.setAttribute('download', 'reservations.csv');
//       link.style.visibility = 'hidden';
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     }
//   };

//   return (
//     <Container>
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <Box display="flex" justifyContent="space-between" mb={4}>
//           <DatePicker
//             label="Check-in"
//             value={selectedDates.checkIn}
//             onChange={(date) => handleDateChange('checkIn', date)}
//             renderInput={(params) => <TextField {...params} />}
//           />
//           <DatePicker
//             label="Check-out"
//             value={selectedDates.checkOut}
//             onChange={(date) => handleDateChange('checkOut', date)}
//             renderInput={(params) => <TextField {...params} />}
//           />
//         </Box>
//       </LocalizationProvider>

//       <Box display="flex" justifyContent="space-between" mb={4}>
//         <Typography variant="body1">Check-in from 2023-08-01</Typography>
//         <Typography variant="body1">Check-in to 2024-05-01</Typography>
//       </Box>

//       <Box display="flex" justifyContent="space-between" mb={4}>
//         <Card sx={{ backgroundColor: '#f0f8ff' }}>
//           <CardContent>
//             <Typography variant="h6">Accommodation</Typography>
//             <Typography variant="h4">€{totalAccommodation.toFixed(2)}</Typography>
//           </CardContent>
//         </Card>
//         <Card sx={{ backgroundColor: '#f0fff0' }}>
//           <CardContent>
//             <Typography variant="h6">Cleaning</Typography>
//             <Typography variant="h4">€{totalCleaning.toFixed(2)}</Typography>
//           </CardContent>
//         </Card>
//         <Card sx={{ backgroundColor: '#fff0f0' }}>
//           <CardContent>
//             <Typography variant="h6">Management</Typography>
//             <Typography variant="h4">€{totalManagement.toFixed(2)}</Typography>
//           </CardContent>
//         </Card>
//       </Box>

//       <Box display="flex" justifyContent="flex-end" mb={2}>
//         <Button variant="contained" color="primary" onClick={downloadCSV}>
//           Download CSV
//         </Button>
//       </Box>

//       <TableContainer>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>Confirmation Code</TableCell>
//               {/* <TableCell>Actions</TableCell> */}
//               <TableCell>CheckIn Date</TableCell>
//               <TableCell>Checkout Date</TableCell>
//               <TableCell>Guest Name</TableCell>
//               {/* <TableCell>Owner Paid At</TableCell> */}
//               <TableCell>Status</TableCell>
//               {/* <TableCell>Balance</TableCell> */}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {filteredReservations.map((reservation) => (
//               <TableRow key={reservation._id || Math.random().toString()}>
//                 <TableCell>{reservation.confirmationCode || 'N/A'}</TableCell>
//                 <TableCell>{reservation.checkInDateLocalized || 'N/A'}</TableCell>
//                 <TableCell>{reservation.checkOutDateLocalized || 'N/A'}</TableCell>
//                 <TableCell>{reservation.guest?.fullName || 'N/A'}</TableCell>
//                 {/* <TableCell>{reservation.listing?.nickname || 'N/A'}</TableCell> */}
//                 {/* <TableCell>{reservation.checkInDateLocalized || 'N/A'}</TableCell> */}
//                 <TableCell>{reservation.status || 'N/A'}</TableCell>
//                 {/* <TableCell>{reservation.money?.balanceDue || 'N/A'}</TableCell> */}
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Container>
//   );
// };

// export default ReservationTable;


import React, { useState } from 'react';
import { Box, Card, CardContent, Container, Table, TableBody, TextField, TableCell, TableContainer, TableHead, TableRow, Typography, Button } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const ReservationTable = ({ reservations }) => {
  const [selectedDates, setSelectedDates] = useState({
    checkIn: null,
    checkOut: null,
  });

  const handleDateChange = (field, date) => {
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [field]: date,
    }));
  };

  const filteredReservations = React.useMemo(() => {
    if (!Array.isArray(reservations)) return [];

    return reservations.filter((reservation) => {
      try {
        const checkInDate = new Date(reservation.checkIn);
        const checkOutDate = new Date(reservation.checkOut);
        const selectedCheckIn = selectedDates.checkIn ? new Date(selectedDates.checkIn) : null;
        const selectedCheckOut = selectedDates.checkOut ? new Date(selectedDates.checkOut) : null;

        return (
          (!selectedCheckIn || checkInDate >= selectedCheckIn) &&
          (!selectedCheckOut || checkOutDate <= selectedCheckOut)
        );
      } catch (error) {
        console.error('Error filtering reservation:', error);
        return false;
      }
    });
  }, [reservations, selectedDates]);

  const calculateTotal = (key) => {
    try {
      return filteredReservations.reduce((sum, reservation) => {
        const value = reservation.money?.[key] || 0;
        return sum + (typeof value === 'number' ? value : 0);
      }, 0);
    } catch (error) {
      console.error(`Error calculating total for ${key}:`, error);
      return 0;
    }
  };

  const totalAccommodation = calculateTotal('fareAccommodation');
  const totalCleaning = calculateTotal('fareCleaning');
  const totalManagement = calculateTotal('hostServiceFee');

  const downloadCSV = () => {
    const headers = [
      'Confirmation Code',
      'CheckIn Date',
      'Checkout Date',
      'Guest Name',
      'Status',
      'Total Price',
      'Accommodation Fee',
      'Taxes',
      'Cleaning Fee',
      'Channel Fees',
      'Net Income',
      'Management Commission',
      'Source'
    ];

    const csvData = filteredReservations.map((reservation) => [
      reservation.confirmationCode || '',
      reservation.checkInDateLocalized || '',
      reservation.checkOutDateLocalized || '',
      reservation.guest?.fullName || '',
      reservation.status || '',
      reservation.money?.totalPrice || '',
      reservation.money?.fareAccommodation || '',
      reservation.money?.taxes || '',
      reservation.money?.fareCleaning || '',
      reservation.money?.channelFees || '',
      reservation.money?.netIncome || '',
      reservation.money?.managementCommission || '',
      reservation.source || ''
    ]);

    const csvContent = [
      headers.join(','),
      ...csvData.map((row) => row.join(',')),
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'reservations.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box display="flex" justifyContent="space-between" mb={4}>
          <DatePicker
            label="Check-in"
            value={selectedDates.checkIn}
            onChange={(date) => handleDateChange('checkIn', date)}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="Check-out"
            value={selectedDates.checkOut}
            onChange={(date) => handleDateChange('checkOut', date)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
      </LocalizationProvider>

      <Box display="flex" justifyContent="space-between" mb={4}>
        <Typography variant="body1">Check-in from 2023-08-01</Typography>
        <Typography variant="body1">Check-in to 2024-05-01</Typography>
      </Box>

      <Box display="flex" justifyContent="space-between" mb={4}>
        <Card sx={{ backgroundColor: '#f0f8ff' }}>
          <CardContent>
            <Typography variant="h6">Accommodation</Typography>
            <Typography variant="h4">€{totalAccommodation.toFixed(2)}</Typography>
          </CardContent>
        </Card>
        <Card sx={{ backgroundColor: '#f0fff0' }}>
          <CardContent>
            <Typography variant="h6">Cleaning</Typography>
            <Typography variant="h4">€{totalCleaning.toFixed(2)}</Typography>
          </CardContent>
        </Card>
        <Card sx={{ backgroundColor: '#fff0f0' }}>
          <CardContent>
            <Typography variant="h6">Management</Typography>
            <Typography variant="h4">€{totalManagement.toFixed(2)}</Typography>
          </CardContent>
        </Card>
      </Box>

      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button variant="contained" color="primary" onClick={downloadCSV}>
          Download CSV
        </Button>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Confirmation Code</TableCell>
              <TableCell>CheckIn Date</TableCell>
              <TableCell>Checkout Date</TableCell>
              <TableCell>Guest Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Total Price</TableCell>
              <TableCell>Accommodation Fee</TableCell>
              <TableCell>Taxes</TableCell>
              <TableCell>Cleaning Fee</TableCell>
              <TableCell>Channel Fees</TableCell>
              <TableCell>Net Income</TableCell>
              <TableCell>Management Commission</TableCell>
              <TableCell>Owner Profit</TableCell>
              <TableCell>Source</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredReservations.map((reservation) => (
              <TableRow key={reservation._id || Math.random().toString()}>
                <TableCell>{reservation.confirmationCode || 'N/A'}</TableCell>
                <TableCell>{reservation.checkInDateLocalized || 'N/A'}</TableCell>
                <TableCell>{reservation.checkOutDateLocalized || 'N/A'}</TableCell>
                <TableCell>{reservation.guest?.fullName || 'N/A'}</TableCell>
                <TableCell>{reservation.status || 'N/A'}</TableCell>
                <TableCell>{reservation.money?.hostPayout || 'N/A'}</TableCell>
                <TableCell>{reservation.money?.fareAccommodation || 'N/A'}</TableCell>
                <TableCell>{reservation.money?.totalTaxes || 'N/A'}</TableCell>
                <TableCell>{reservation.money?.fareCleaning || 'N/A'}</TableCell>
                <TableCell>{reservation.money?.hostServiceFee || 'N/A'}</TableCell>
                <TableCell>{reservation.money?.netIncome || 'N/A'}</TableCell>
                <TableCell>{reservation.money?.commission || 'N/A'}</TableCell>
                <TableCell>{reservation.money?.ownerRevenue || 'N/A'}</TableCell>

                <TableCell>{reservation.source || 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default ReservationTable;