

import React from 'react';
import { Container, Grid, Paper, Typography, Box, Divider, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, AppBar, Toolbar } from '@mui/material';
import { styled } from '@mui/system';

const RootContainer = styled(Container)({
  maxWidth: '100%',
  padding: '20px',
  boxSizing: 'border-box',
});

const SummaryPaper = styled(Paper)({
  padding: '20px',
  marginBottom: '20px',
  backgroundColor: '#f5f5f5',
});

const GuestInfoPaper = styled(Paper)({
  padding: '20px',
  marginBottom: '20px',
  backgroundColor: '#f5f5f5',
});

const FinancePaper = styled(Paper)({
  padding: '20px',
  backgroundColor: '#f5f5f5',
});

const ResponsiveGrid = styled(Grid)({
  flexWrap: 'wrap',
  '& .MuiGrid-item': {
    width: '100%',
    '@media (min-width: 600px)': {
      width: 'auto',
    },
  },
});

const DetailedReservation = ({ reservation }) => {
  const renderCustomFields = (fields) => {
    if (!fields) return 'None';
    if (Array.isArray(fields)) {
      return fields.map(field => (
        <Box key={field._id}>
          <Typography variant="body2">{field.fieldId}: {field.value}</Typography>
        </Box>
      ));
    }
    return JSON.stringify(fields);
  };

  return (
    <RootContainer>
      <AppBar position="static" color="primary">
        <Toolbar>
          <ResponsiveGrid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={2}>
              <Typography variant="subtitle2">RESERVATION</Typography>
              <Typography variant="body2">{reservation?.guest?.fullName || ''}</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="subtitle2">STATUS</Typography>
              <Typography variant="body2" style={{ color: reservation?.status === 'Confirmed' ? 'green' : 'orange' }}>
                {reservation?.status || ''}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="subtitle2">CHECK IN</Typography>
              <Typography variant="body2">{reservation?.checkInDateLocalized || ''}</Typography>
              <Typography variant="caption">{reservation?.checkInTime || ''}</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="subtitle2">CHECK OUT</Typography>
              <Typography variant="body2">{reservation?.checkOutDateLocalized || ''}</Typography>
              <Typography variant="caption">{reservation?.checkOutTime || ''}</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="subtitle2">GUESTS</Typography>
              <Typography variant="body2">{reservation?.guestsCount || ''}</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="subtitle2">LISTING</Typography>
              <Typography variant="body2">{reservation?.listing?.nickname || ''}</Typography>
            </Grid>
          </ResponsiveGrid>
        </Toolbar>
      </AppBar>
      <Grid container spacing={3} mt={2}>
        <Grid item xs={12} md={8}>
          <SummaryPaper>
            <Typography variant="h6" gutterBottom>
              Summary
            </Typography>
            <Divider />
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Price:</Typography>
                <Typography variant="body1">€{reservation?.money?.guestTotalPrice || ''}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Guests:</Typography>
                <Typography variant="body1">{reservation?.guestsCount || ''}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Nights:</Typography>
                <Typography variant="body1">{reservation?.nightsCount || ''}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Channel:</Typography>
                <Typography variant="body1">{reservation?.source || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">PreCheck In:</Typography>
                <Typography variant="body1">{reservation?.preCheckIn || 'Not Filled'}</Typography>
              </Grid>
            </Grid>
            <Box mt={2}>
              <Typography variant="subtitle1">Notes:</Typography>
              <TextField
                label="Add note"
                variant="outlined"
                size="small"
                fullWidth
                multiline
                rows={2}
                defaultValue={reservation?.notes?.other || ''}
              />
            </Box>
            <Box mt={2}>
              <Typography variant="subtitle1">Custom Fields:</Typography>
              {renderCustomFields(reservation?.customFields)}
            </Box>
            <Box mt={2}>
              <Typography variant="subtitle1">Channel Notes:</Typography>
              <Typography variant="body1">{reservation?.channelNotes || 'None'}</Typography>
            </Box>
          </SummaryPaper>
          <GuestInfoPaper>
            <Typography variant="h6" gutterBottom>
              Guest Information
            </Typography>
            <Divider />
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Name:</Typography>
                <Typography variant="body1">{reservation?.guest?.fullName || ''}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Phone:</Typography>
                <Typography variant="body1">{reservation?.guest?.phone || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Email:</Typography>
                <Typography variant="body1">{reservation?.guest?.email || ''}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Receives Marketing:</Typography>
                <Typography variant="body1">{reservation?.guest?.receivesMarketing ? 'Yes' : 'No'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Request Parking:</Typography>
                <Typography variant="body1">{reservation?.guest?.requestParking ? 'Yes' : 'No'}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Special Requests:</Typography>
                <Typography variant="body1">{reservation?.guest?.specialRequests || 'None'}</Typography>
              </Grid>
            </Grid>
          </GuestInfoPaper>
        </Grid>
        <Grid item xs={12} md={4}>
          <FinancePaper>
            <Typography variant="h6" gutterBottom>
              Finance | <span style={{ color: 'red' }}>Balance: €{reservation?.money?.balanceDue || ''}</span>
            </Typography>
            <Divider />
            <Box mt={2}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Invoices</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Cleaning</TableCell>
                      <TableCell>€{reservation?.money?.fareCleaning || ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Accommodation</TableCell>
                      <TableCell>€{reservation?.money?.fareAccommodation || ''}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </FinancePaper>
        </Grid>
      </Grid>
    </RootContainer>
  );
};

export default DetailedReservation;
