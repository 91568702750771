

import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCH64Ia5pwW80F_M0tC5dD8J0dIUKVSQMA",
    authDomain: "concierge-1503616185202.firebaseapp.com",
    projectId: "concierge-1503616185202",
    storageBucket: "concierge-1503616185202.appspot.com",
    messagingSenderId: "982849576084",
    appId: "1:982849576084:web:f5a6cacbe7fcfb1e03a881",
    measurementId: "G-GSDG7WFZH3"
  };

// firebase.initializeApp(firebaseConfig);

// export const auth = firebase.auth();

// export const firestore = firebase.firestore();

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const firestore = getFirestore(app);