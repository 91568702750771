

import React, { useState, useEffect, useContext } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import api from '../api';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';

import UserContext from '../UserContext';

const AccountantComponent = () => {
  const { isAdmin, user } = useContext(UserContext);
  const [owners, setOwners] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterBalance, setFilterBalance] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedOwnerId, setSelectedOwnerId] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState('');
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    fetchOwners();
  }, []);

//   const fetchOwners = async () => {
//     try {
//       const userId = user.id;
//       const response = await api.get('/api/owners', {
//         params: { userId },
//       });
//       console.log(response.data);
//       setOwners(response.data);
//     } catch (error) {
//       console.error('Error fetching owners:', error);
//     }
//   };

const fetchOwners = async () => {
    try {
      const userId = user.id;
      const response = await api.get('/api/owners', {
        params: { userId },
      });
      console.log(response.data);
      setOwners(response.data);
      setLoading(false); // Set loading to false after owners are fetched
    } catch (error) {
      console.error('Error fetching owners:', error);
      setLoading(false); // Set loading to false even if there's an error
    }
  };

//   const handlePay = (ownerId) => {
//     setSelectedOwnerId(ownerId);
//     setOpenModal(true);
//   };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedOwnerId(null);
    setPaymentAmount('');
  };

  const handlePaymentAmountChange = (event) => {
    setPaymentAmount(event.target.value);
  };

  const handlePaymentSubmit = async () => {
    try {
      await api.put(`/api/owners/${selectedOwnerId}/balance`, {
        amount: parseFloat(paymentAmount),
      }, {
        params: { userId: user.id },
      });
      fetchOwners();
      handleCloseModal();
    } catch (error) {
      console.error('Error submitting payment:', error);
    }
  };

  const handlePay = (ownerId, balance) => {
    setSelectedOwnerId(ownerId);
    setPaymentAmount(balance ? balance.toString() : "0");
    setOpenModal(true);
  };


  const handleFullAmountClick = () => {
    const selectedOwner = owners.find((owner) => owner._id === selectedOwnerId);
    if (selectedOwner) {
      setPaymentAmount(selectedOwner.balance.toString());
    }
  };


  const handleListingClick = (listingId) => {
    navigate(`/listings/${listingId}`);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterBalanceChange = (event) => {
    setFilterBalance(event.target.value);
  };

  const filteredOwners = owners.filter((owner) => {
    const fullName = owner.fullName ? owner.fullName.toLowerCase() : ''
    // const email = owner.email;
    // const searchTermLower = searchTerm;
    // const balance = owner.balance ? owner.balance.toString() : '';
    // const balanceFilter = filterBalance === '' || balance === filterBalance;
    const email = owner.email ? owner.email.toLowerCase() : '';
    const searchTermLower = searchTerm.toLowerCase();
    const balance = owner.balance ? owner.balance.toString() : '';
    const balanceFilter = filterBalance === '' || balance === filterBalance;

    return (fullName.includes(searchTermLower) || email.includes(searchTermLower)) && balanceFilter;
  });

  return (
    <div>
    {loading ? (
      <LoadingScreen />
    ) : (
      <>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ width: '300px' }}
        />
        <Select
          value={filterBalance}
          onChange={handleFilterBalanceChange}
          displayEmpty
          style={{ width: '200px' }}
        >
          <MenuItem value="">All Balances</MenuItem>
          <MenuItem value="0">Balance: $0</MenuItem>
          <MenuItem value="1000">Balance: $1000</MenuItem>
          <MenuItem value="5000">Balance: $5000</MenuItem>
        </Select>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Full Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Listings</TableCell>
              <TableCell>Balance</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredOwners.map((owner) => (
              <TableRow key={owner._id}>
                <TableCell>{owner.fullName}</TableCell>
                <TableCell>{owner.email}</TableCell>
                <TableCell>
                  {owner.listings && (
                    <div>
                      {owner.listings.map((listing) => (
                        <Button
                          key={listing._id}
                          variant="outlined"
                          size="small"
                          style={{ marginRight: '5px', marginBottom: '5px' }}
                          onClick={() => handleListingClick(listing._id)}
                        >
                          {listing.nickname}
                        </Button>
                      ))}
                    </div>
                  )}
                </TableCell>
                <TableCell>{owner.balance ? `€${owner.balance.toFixed(2)}` : 'N/A'}</TableCell>

                <TableCell>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handlePay(owner._id, owner.balance)}
                  >
                    Pay
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Payment</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Amount"
            type="number"
            fullWidth
            value={paymentAmount}
            onChange={handlePaymentAmountChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={handlePaymentSubmit} variant="contained" color="primary">
            Pay
          </Button>
        </DialogActions>
      </Dialog>
      </>
    )}
    </div>
  );
};

export default AccountantComponent;