import React from 'react';
import { useParams } from 'react-router-dom';

const PropertyDetails = () => {
  const { id: propertyId } = useParams();
  // Fetch property details based on the propertyId
  // Render the property details
  return (
    <div className="property-details">
      <h2>Property Details</h2>
      <p>Property ID: {propertyId}</p>
      {/* Render property details */}
    </div>
  );
};

export default PropertyDetails;