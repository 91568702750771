




import React, { useState, useEffect, useContext } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { Container, Card, CardContent, Typography, Box, Button, Switch, IconButton, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PercentIcon from '@mui/icons-material/Percent';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import BalanceIcon from '@mui/icons-material/Balance';
import GetAppIcon from '@mui/icons-material/GetApp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import api from '../../api';
import UserContext from '../../UserContext';

const FinancePage = ({ property }) => {
  const [isYearly, setIsYearly] = useState(false);
  const [period, setPeriod] = useState(new Date().toLocaleString('default', { month: 'long', year: 'numeric' })); // Default period to the current month
  const [stats, setStats] = useState({ income: {}, fees: { total: 0, management: 0, processing: 0, channel: 0 } });
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchReservationData = async () => {
      try {
        const response = await api.get(`/api/reservations/${property._id}`, {
          params: {
            userId: user.id,
          },
        });

        if (response.status === 200 && response.data) {
          const reservationsData = response.data;
          const reservationsArray = Object.values(reservationsData);
          return reservationsArray;
        } else {
          console.error('Unexpected reservation data format:', response.data);
          return [];
        }
      } catch (error) {
        console.error('Error fetching reservation data:', error);
        return [];
      }
    };

    const fetchData = async () => {
      const reservationsArray = await fetchReservationData();
      calculateStats(reservationsArray);
    };

    fetchData();
  }, [property._id, user.id, period, isYearly]);

  const calculateStats = (reservations) => {
    const income = {};
    let totalFees = 0;
    let managementFee = 0;
    let processingFee = 0;
    let channelFee = 0;

    reservations.forEach((reservation) => {
      const checkInDate = new Date(reservation.checkIn);
      const year = checkInDate.getFullYear();
      const month = checkInDate.toLocaleString('default', { month: 'long' });

      const periodMatch = isYearly
        ? period === year.toString()
        : `${month} ${year}` === period;

      if (periodMatch) {
        const channel = reservation.source;
        const fareAccommodation = reservation.money.fareAccommodationAdjusted || 0;
        const totalFeesForReservation = reservation.money.totalFees || 0;
        const managementFeeForReservation = reservation.money.commission || 0;
        const processingFeeForReservation = reservation.money.subTotalPrice * 0.035 || 0;
        const channelFeeForReservation = reservation.money.hostServiceFee || 0;

        if (!income[channel]) {
          income[channel] = 0;
        }
        income[channel] += fareAccommodation;
        totalFees += totalFeesForReservation + managementFeeForReservation + processingFeeForReservation + channelFeeForReservation;
        managementFee += managementFeeForReservation;
        processingFee += processingFeeForReservation;
        channelFee += channelFeeForReservation;
      }
    });

    // Fill missing channels and fees with zero for a complete view
    const allChannels = reservations.reduce((channels, reservation) => {
      channels.add(reservation.source);
      return channels;
    }, new Set());

    allChannels.forEach(channel => {
      if (!income[channel]) {
        income[channel] = 0;
      }
    });

    setStats({
      income,
      fees: {
        total: totalFees,
        management: managementFee,
        processing: processingFee,
        channel: channelFee
      }
    });
  };

  const handleToggle = () => {
    setIsYearly(!isYearly);
    setPeriod(!isYearly ? new Date().getFullYear().toString() : new Date().toLocaleString('default', { month: 'long', year: 'numeric' }));
  };

  const handlePeriodChange = (direction) => {
    if (isYearly) {
      const year = parseInt(period, 10);
      setPeriod((direction === 'prev' ? year - 1 : year + 1).toString());
    } else {
      let [month, year] = period.split(' ');
      month = new Date(`${month} 1, ${year}`).getMonth();
      year = parseInt(year, 10);

      if (direction === 'prev') {
        if (month === 0) {
          month = 11;
          year -= 1;
        } else {
          month -= 1;
        }
      } else {
        if (month === 11) {
          month = 0;
          year += 1;
        } else {
          month += 1;
        }
      }

      setPeriod(new Date(year, month, 1).toLocaleString('default', { month: 'long', year: 'numeric' }));
    }
  };

  const downloadCSV = () => {
    const csvContent = [
      ['Category', 'Subcategory', 'Amount'],
      ...Object.entries(stats.income).map(([channel, amount]) => [channel, 'Income', amount.toFixed(2)]),
      ['Management Fee', 'Expense', stats.fees.management.toFixed(2)],
      ['Processing Fee', 'Expense', stats.fees.processing.toFixed(2)],
      ['Channel Fee', 'Expense', stats.fees.channel.toFixed(2)],
      ['Total Income', '', totalIncome],
      ['Total Expenses', '', totalExpenses]
      // ['Balance', '', balance]
    ].map(row => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'finance-data.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [['Category', 'Subcategory', 'Amount']],
      body: Object.entries(stats.income).map(([channel, amount]) => [channel, 'Income', `€${amount.toFixed(2)}`]),
    });
    doc.save('finance-data.pdf');
  };

  const totalIncome = Object.values(stats.income).reduce((acc, val) => acc + val, 0).toFixed(2);
  const totalExpenses = stats.fees.total.toFixed(2);
  const balance = (totalIncome - totalExpenses).toFixed(2);

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1">{isYearly ? 'Yearly' : 'Monthly'} Stats</Typography>
          <Switch checked={isYearly} onChange={handleToggle} color="primary" />
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton onClick={() => handlePeriodChange('prev')}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="subtitle1">{period}</Typography>
          <IconButton onClick={() => handlePeriodChange('next')}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={3}>
        <Card style={{ backgroundColor: '#E6F4EA', flex: 1, margin: '0 8px' }}>
          <CardContent>
            <Box display="flex" alignItems="center" mb={1}>
              <ArrowUpwardIcon style={{ fontSize: '16px', color: 'green' }} />
              <Typography variant="subtitle1" style={{ paddingLeft: '8px' }}>Income</Typography>
            </Box>
            <Typography variant="h6" style={{ color: 'green' }}>
              €{totalIncome}
            </Typography>
          </CardContent>
        </Card>
        <Card style={{ backgroundColor: '#FCEBEA', flex: 1, margin: '0 8px' }}>
          <CardContent>
            <Box display="flex" alignItems="center" mb={1}>
              <ArrowDownwardIcon style={{ fontSize: '16px', color: 'red' }} />
              <Typography variant="subtitle1" style={{ paddingLeft: '8px' }}>Expenses</Typography>
            </Box>
            <Typography variant="h6" style={{ color: 'red' }}>
              €{totalExpenses}
            </Typography>
          </CardContent>
        </Card>

      </Box>
      <Card>
        <CardContent>
          <Box display="flex" alignItems="center" mb={3}>
            <ArrowUpwardIcon style={{ fontSize: '16px', color: 'green' }} />
            <Typography variant="subtitle1" style={{ paddingLeft: '8px' }}>Income</Typography>
            <Typography variant="subtitle1" style={{ marginLeft: 'auto', color: 'green' }}>
              €{totalIncome}
            </Typography>
          </Box>
          {!isYearly && Object.entries(stats.income).map(([channel, amount]) => (
            <Accordion key={channel}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{channel}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{channel}: €{amount.toFixed(2)}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </CardContent>
      </Card>
      <Card style={{ marginTop: '16px' }}>
        <CardContent>
          <Box display="flex" alignItems="center" mb={3}>
            <ArrowDownwardIcon style={{ fontSize: '16px', color: 'red' }} />
            <Typography variant="subtitle1" style={{ paddingLeft: '8px' }}>Expenses</Typography>
            <Typography variant="subtitle1" style={{ marginLeft: 'auto', color: 'red' }}>
              €{totalExpenses}
            </Typography>
          </Box>
          {!isYearly && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Fees</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Management Fee: €{stats.fees.management.toFixed(2)}</Typography>
                <Typography>Processing Fee: €{stats.fees.processing.toFixed(2)}</Typography>
                <Typography>Channel Fee: €{stats.fees.channel.toFixed(2)}</Typography>
              </AccordionDetails>
            </Accordion>
          )}
        </CardContent>
      </Card>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={3}>
        <Typography>Download:</Typography>
        <Button startIcon={<GetAppIcon />} onClick={downloadCSV}>CSV</Button>
        <Button startIcon={<GetAppIcon />} onClick={downloadPDF}>PDF</Button>
      </Box>
    </Container>
  );
};

export default FinancePage;