import React, { useState, useContext } from 'react';
import { AppBar, Toolbar, Typography, InputBase, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, useMediaQuery, useTheme, Box } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import UserContext from '../UserContext';
import logo from '../library/smartbnblogo.png';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch',
    },
  },
}));

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { handleToggleSidebar } = useContext(UserContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchOpen = () => {
    setSearchOpen(true);
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: 'white', color: 'black' }}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleToggleSidebar}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="Logo" style={{ height: '40px' }} />
        </Box>
        {isMobile ? (
          <IconButton color="inherit" onClick={handleSearchOpen}>
            <SearchIcon />
          </IconButton>
        ) : (
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <Search sx={{ backgroundColor: alpha('#000', 0.05), '&:hover': { backgroundColor: alpha('#000', 0.1) } }}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Current listing"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </Box>
        )}
        <IconButton
          size="large"
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={handleClick}
        >
          <AddIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>Reservation</MenuItem>
          <MenuItem onClick={handleClose}>New Listing</MenuItem>
        </Menu>
        <Dialog open={searchOpen} onClose={handleSearchClose}>
          <DialogTitle>
            Search
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleSearchClose}
              aria-label="close"
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Current listing"
                inputProps={{ 'aria-label': 'search' }}
                autoFocus
                fullWidth
              />
            </Search>
          </DialogContent>
        </Dialog>
      </Toolbar>
      <Box sx={{ height: '1px', backgroundColor: 'black' }} />
    </AppBar>
  );

  // return (
  //   <AppBar position="static">
  //     <Toolbar>
  //       <IconButton
  //         size="large"
  //         edge="start"
  //         color="inherit"
  //         aria-label="menu"
  //         onClick={handleToggleSidebar}
  //         sx={{ mr: 2 }}
  //       >
  //         <MenuIcon />
  //       </IconButton>
  //       <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
  //         Smartbnb
  //       </Typography>
  //       {isMobile ? (
  //         <IconButton color="inherit" onClick={handleSearchOpen}>
  //           <SearchIcon />
  //         </IconButton>
  //       ) : (
  //         <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
  //           <Search>
  //             <SearchIconWrapper>
  //               <SearchIcon />
  //             </SearchIconWrapper>
  //             <StyledInputBase
  //               placeholder="Current listing"
  //               inputProps={{ 'aria-label': 'search' }}
  //             />
  //           </Search>
  //         </Box>
  //       )}
  //       <IconButton
  //         size="large"
  //         edge="end"
  //         color="inherit"
  //         aria-label="menu"
  //         onClick={handleClick}
  //       >
  //         <AddIcon />
  //       </IconButton>
  //       <Menu
  //         anchorEl={anchorEl}
  //         open={Boolean(anchorEl)}
  //         onClose={handleClose}
  //       >
  //         <MenuItem onClick={handleClose}>Reservation</MenuItem>
  //         <MenuItem onClick={handleClose}>New Listing</MenuItem>
  //       </Menu>
  //       <Dialog open={searchOpen} onClose={handleSearchClose}>
  //         <DialogTitle>
  //           Search
  //           <IconButton
  //             edge="end"
  //             color="inherit"
  //             onClick={handleSearchClose}
  //             aria-label="close"
  //             sx={{ position: 'absolute', right: 8, top: 8 }}
  //           >
  //             <CloseIcon />
  //           </IconButton>
  //         </DialogTitle>
  //         <DialogContent>
  //           <Search>
  //             <SearchIconWrapper>
  //               <SearchIcon />
  //             </SearchIconWrapper>
  //             <StyledInputBase
  //               placeholder="Current listing"
  //               inputProps={{ 'aria-label': 'search' }}
  //               autoFocus
  //               fullWidth
  //             />
  //           </Search>
  //         </DialogContent>
  //       </Dialog>
  //     </Toolbar>
  //   </AppBar>
  // );
};

export default Header;