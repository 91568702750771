import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ListingsComponent from '../components/ListingsComponent';
import listings_dummy from '../data/dummyData';
import UserContext from '../UserContext';
import SettingsComponent from '../components/SettingsComponent';
import AccountantComponent from '../components/AccountantComponent';

const AccountantPage = () => {
    const { user } = useContext(UserContext);


    return (
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Header />
        <Box display="flex" flexGrow={1}>
          <Sidebar />
          <Box flexGrow={1} p={3}>
            <AccountantComponent />
          </Box>
        </Box>
      </Box>
    );
};

export default AccountantPage;



