



import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, TextField, Select, MenuItem, Table, Alert,TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Switch, Modal, FormControl, InputLabel ,Snackbar,Autocomplete} from '@mui/material';
import { Search as SearchIcon, Edit as EditIcon, VpnKey as VpnKeyIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import { auth, firestore } from '../firebase';
import { createUserWithEmailAndPassword , updateCurrentUser } from 'firebase/auth';
import { collection, doc, setDoc, getDocs,updateDoc } from 'firebase/firestore';
import listings_dummy from '../data/dummyData';
import UserContext from '../UserContext';
import api from '../api';

const SettingsComponent = () => {
  const { listings, isAdmin, user } = useContext(UserContext);
  console.log(listings)
  const [users, setUsers] = useState([]);

  useEffect(() => {
    
    const getUsers = async () => {
      const usersData = await fetchUsers();
      console.log(usersData)
      setUsers(usersData);
    };

    getUsers();
    
  }, []);

  const fetchUsers = async () => {
      try {
        const usersSnapshot = await getDocs(collection(firestore, 'users'));
        const usersData = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(usersData)
        return usersData;
      } catch (error) {
        console.error('Error fetching users:', error);
        return [];
      }
    };

  const [open, setOpen] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [email, setEmail] = useState('');

  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [propertyIds, setPropertyIds] = useState([]);
  const [openPasswordResetModal, setOpenPasswordResetModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('Active');
  const [payrollFilter, setPayrollFilter] = useState(false);
  const [preferredFilter, setPreferredFilter] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackSeverity, setFeedbackSeverity] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [activeOnly, setActiveOnly] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [selectedUserId, setSelectedUserId] = useState('');

  const [openEditModal, setOpenEditModal] = useState(false);
  const [editedUser, setEditedUser] = useState({
    role: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    bankAccountNumbers: [],
    abilities: [],
    listings: [],
    });

  const handleEditModalOpen = (user) => {
        setEditedUser({
          id: user.id || '',
          role: user.role || '',
          firstName: user.firstName || '',
          lastName: user.lastName || '',
          email: user.email || '',
          phoneNumber: user.phoneNumber || '',
          bankAccountNumbers: user.bankAccountNumbers || [],
          abilities: user.abilities || [],
          listings: user.listings || [],
        });
        setOpenEditModal(true);
      };

  const handleEditModalClose = () => {
        setOpenEditModal(false);
        setEditedUser({
          role: '',
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          bankAccountNumbers: [],
          abilities: [],
          listings: [],
        });
      };
      

  const handleSaveEditedUser = async () => {
        try {
          console.log(editedUser)
          await updateUser1(editedUser.id, editedUser);
          handleEditModalClose();
          setSnackbarMessage('User details updated successfully!');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        } catch (error) {
          console.error('Error updating user details:', error);
          setSnackbarMessage('Error updating user details. Please try again.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      };
      

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handlePasswordResetModalOpen = (userId) => {
    setSelectedUserId(userId);
    setOpenPasswordResetModal(true);
  };
  
  const handlePasswordResetModalClose = () => {
    setOpenPasswordResetModal(false);
    setNewPassword('');
    setConfirmPassword('');
    setSelectedUserId('');

  };

  const handleUserRoleChange = (event) => {
    setUserRole(event.target.value);
  };


  const handleAddUser = async (event) => {
    event.preventDefault();
  
    try {
      // Get the current admin user before creating the new user
      const adminUser = auth.currentUser;
  
      // Create user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Store additional user details in Firestore
      await setDoc(doc(collection(firestore, 'users'), user.uid), {
        role: userRole,
        firstName,
        lastName,
        email,
        companyName,
        phoneNumber,
        propertyIds,
      });
  
      // Switch back to the admin user
      await updateCurrentUser(auth, adminUser);
      await sendWelcomeEmail(email, password);
  
      // Clear form fields and close the modal
      setUserRole('');
      setEmail('');
      setPassword('');
      setPhoneNumber('');
      setFirstName('');
      setLastName('');
      setCompanyName('');
      setPropertyIds([]);
      handleClose();
      setFeedbackMessage('User registered successfully!');
      setFeedbackSeverity('success');
    } catch (error) {
      console.error('Error adding user:', error);
      setFeedbackMessage('Error registering user. Please try again.');
      setFeedbackSeverity('error');
    }
  };

  // const sendWelcomeEmail = async (email, password) => {
  //   console.log("email",email)
  //   console.log("password",password)

  //   try {
  //     const response = await api.post('/api/send-welcome-email', {
  //       email,
  //       password,
  //       loginUrl: 'https://portal.smartbnb.gr/login' // Replace with your actual login URL
  //     }, {
  //       params: { userId: user.id }, // Assuming you have access to the user object
  //     });
  
  //     if (response.status !== 200) {
  //       throw new Error('Failed to send welcome email');
  //     }
  
  //     console.log('Welcome email sent successfully');
  //   } catch (error) {
  //     console.error('Error sending welcome email:', error);
  //     throw error; // Rethrow to handle in the calling function
  //   }
  // };

  const sendWelcomeEmail = async (email, password) => {


    try {
        const response = await api.post('/api/send-welcome-email', {
            email: JSON.stringify(email),
            password: JSON.stringify(password),
            loginUrl: 'https://portal.smartbnb.gr/login'
        }, {
            params: { userId: user.id },
        });

        if (response.status !== 200) {
            throw new Error('Failed to send welcome email');
        }

        // console.log('Welcome email sent successfully');
    } catch (error) {
        console.error('Error sending welcome email:', error);
        throw error;
    }
};



  const updateUser1 = async (userId, updatedData) => {
    try {
      const userRef = doc(firestore, 'users', userId);
      await updateDoc(userRef, updatedData);
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  };

  const updateUser = async (userId, updatedData) => {
    try {
      await updateDoc(doc(firestore, 'users', userId), updatedData);
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  };
 const updatePassword = async (userId, newPassword) => {
    await updateUser(userId, { password: newPassword });
  };

  const uniqueListings = listings.reduce((acc, current) => {
    const existingListing = acc.find(listing => listing._id === current._id);
    if (!existingListing) {
      return [...acc, current];
    }
    return acc;
  }, []);

  const handlePasswordReset = async (userId) => {
    if (newPassword !== confirmPassword) {
      setFeedbackMessage('Passwords do not match.');
      setFeedbackSeverity('error');
      return;
    }
  
    try {
      await updatePassword(selectedUserId, newPassword);
      handlePasswordResetModalClose();
      setFeedbackMessage('Password reset successfully!');
      setFeedbackSeverity('success');
      alert('Password reset successfully!');
      setNewPassword('');
      setConfirmPassword('');
      setSelectedUserId('');
    } catch (error) {
      console.error('Error resetting password:', error);
      setFeedbackMessage('Error resetting password. Please try again.');
      setFeedbackSeverity('error');
      alert('Error resetting password. Please try again.');
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handlePayrollChange = (event) => {
    setPayrollFilter(event.target.checked);
  };

  const handlePreferredChange = (event) => {
    setPreferredFilter(event.target.checked);
  };


  const filteredUsers = users.filter((user) => {
    const isSearchMatch =
      user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase());

    const isRoleMatch = selectedRole === '' || user.role === selectedRole;
    const isDepartmentMatch = selectedDepartment === '' || user.department === selectedDepartment;
    // const isStatusMatch = selectedStatus === 'Active' ? user.active : !user.active;
    // const isPayrollMatch = !payrollFilter || user.payroll;
    // const isPreferredMatch = !preferredFilter || user.preferred;
    return isSearchMatch && isRoleMatch && isDepartmentMatch;


    //return isSearchMatch && isRoleMatch && isDepartmentMatch && isStatusMatch && isPayrollMatch && isPreferredMatch;
  });

  return (
    <Box p={3}>
        {feedbackMessage && (
        <Alert severity={feedbackSeverity} onClose={() => setFeedbackMessage('')}>
            {feedbackMessage}
        </Alert>
        )}
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>

        <TextField
          placeholder="Search by..."
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearch}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
        />
        <Select variant="outlined" size="small" value={selectedRole} onChange={handleRoleChange}>
          <MenuItem value="">All Roles</MenuItem>
          {/* Add role options */}
        </Select>
        <Select variant="outlined" size="small" value={selectedDepartment} onChange={handleDepartmentChange}>
          <MenuItem value="">All Departments</MenuItem>
          {/* Add department options */}
        </Select>
        <Select variant="outlined" size="small" value={selectedStatus} onChange={handleStatusChange}>
          <MenuItem value="Active">Active</MenuItem>
          <MenuItem value="Inactive">Inactive</MenuItem>
        </Select>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" mr={1}>Payroll</Typography>
          <Switch color="primary" checked={payrollFilter} onChange={handlePayrollChange} />
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" mr={1}>Preferred</Typography>
          <Switch color="primary" checked={preferredFilter} onChange={handlePreferredChange} />
        </Box>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Add User
        </Button>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>First</TableCell>
              <TableCell>Last</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Department</TableCell>
              <TableCell>Zone</TableCell>
              <TableCell>Region</TableCell>
              <TableCell>Payroll</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>

                <TableCell>{user.role}</TableCell>
                <TableCell>{user.firstName}</TableCell>
                <TableCell>{user.lastName}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.department}</TableCell>
                <TableCell>{user.zone}</TableCell>
                <TableCell>{user.region}</TableCell>
                <TableCell>
                  <Switch checked={user.payroll} color="primary" />
                </TableCell>
                <TableCell>
                  <Switch checked={user.active} color="primary" />
                </TableCell>
                <TableCell>
                  <Button size="small" startIcon={<EditIcon />}onClick={() => handleEditModalOpen(user)}>Edit</Button>
                  <Button size="small" startIcon={<VpnKeyIcon />} onClick={() => handlePasswordResetModalOpen(user.id)}>Password</Button>
                  <Button size="small" startIcon={<AccountCircleIcon />}>Login</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={openPasswordResetModal} onClose={handlePasswordResetModalClose}>
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            width: 400,
          }}>
            <Typography variant="h6" component="h2" gutterBottom>
            Reset Password
            </Typography>
            <TextField
            label="New Password"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            fullWidth
            margin="normal"
            />
            <TextField
            label="Confirm New Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
            margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handlePasswordReset}>
            Reset
            </Button>
            <Button variant="outlined" onClick={handlePasswordResetModalClose}>
            Cancel
            </Button>
        </Box>
        </Modal>

        <Modal open={openEditModal} onClose={handleEditModalClose}>
  <Box sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  }}>
    <Typography variant="h6" component="h2" gutterBottom>
      Edit User Details
    </Typography>
    <TextField
      label="Role"
      value={editedUser.role}
      onChange={(e) => setEditedUser({ ...editedUser, role: e.target.value })}
      fullWidth
      margin="normal"
    />
    <TextField
      label="First Name"
      value={editedUser.firstName}
      onChange={(e) => setEditedUser({ ...editedUser, firstName: e.target.value })}
      fullWidth
      margin="normal"
    />
    <TextField
      label="Last Name"
      value={editedUser.lastName}
      onChange={(e) => setEditedUser({ ...editedUser, lastName: e.target.value })}
      fullWidth
      margin="normal"
    />
    <TextField
      label="Email"
      value={editedUser.email}
      onChange={(e) => setEditedUser({ ...editedUser, email: e.target.value })}
      fullWidth
      margin="normal"
    />
    <TextField
      label="Phone Number"
      value={editedUser.phoneNumber}
      onChange={(e) => setEditedUser({ ...editedUser, phoneNumber: e.target.value })}
      fullWidth
      margin="normal"
    />
    <TextField
      label="Bank Account Numbers"
      value={editedUser.bankAccountNumbers.join(', ')}
      onChange={(e) => setEditedUser({ ...editedUser, bankAccountNumbers: e.target.value.split(', ') })}
      fullWidth
      margin="normal"
    />
    <TextField
      label="Abilities"
      value={editedUser.abilities.join(', ')}
      onChange={(e) => setEditedUser({ ...editedUser, abilities: e.target.value.split(', ') })}
      fullWidth
      margin="normal"
    />

  <Autocomplete
    multiple
    options={uniqueListings}
    getOptionLabel={(option) => option.nickname || ''}
    value={editedUser.listings}
    onChange={(event, selectedListings) => {
      const listingIds = selectedListings.map((listing) => listing._id);
      setEditedUser({ ...editedUser, listings: selectedListings, listingIds });
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Listings"
        margin="normal"
        fullWidth
      />
    )}
    isOptionEqualToValue={(option, value) => option._id === value._id}
    renderOption={(props, option) => (
      <li {...props} key={option._id}>
        {option.nickname}
      </li>
    )}
  />
    {/* <Autocomplete
      multiple
      options={listings_dummy}
      getOptionLabel={(option) => option.name}
      value={editedUser.listings}
      onChange={(event, selectedListings) => {
        const listingIds = selectedListings.map((listing) => listing.id);
        setEditedUser({ ...editedUser, listings: selectedListings, listingIds });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Listings"
          margin="normal"
          fullWidth
        />
      )}
    /> */}
        <Button variant="contained" color="primary" onClick={handleSaveEditedUser}>
        Save
        </Button>
        <Button variant="outlined" onClick={handleEditModalClose}>
        Cancel
        </Button>
    </Box>
    </Modal>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            width: 400,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Add User
          </Typography>

          <form onSubmit={handleAddUser}>
            <FormControl fullWidth margin="normal">
              <InputLabel>User Role</InputLabel>
              <Select value={userRole} onChange={handleUserRoleChange} required>
                <MenuItem value="">Select User Role</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="user">User</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Phone Number"
              type="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              fullWidth
              margin="normal"
            />
            {/* Add input fields for property IDs */}
            <Box mt={2}>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
              <Button onClick={handleClose} variant="outlined" color="secondary" style={{ marginLeft: '8px' }}>
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default SettingsComponent;