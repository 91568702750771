import React, { useContext,useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ListingsComponent from '../components/ListingsComponent';
import listings_dummy from '../data/dummyData';
import UserContext from '../UserContext';
import DetailedReservation from '../components/DetailedReservationComponent';
import api from '../api';
import LoadingScreen from '../components/LoadingScreen';

const DetailedReservationPage = () => {
  const { id } = useParams();
  const { user ,isAdmin, listings} = useContext(UserContext);

  const [reservation, setReservation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userId = user.id

  useEffect(() => {
    const fetchReservation = async () => {
      
      
      try {
        console.log("only this")
        const response = await api.get(`/api/reservationAll/${id}`, {
          params: { userId },
        });
        console.log(response.data[0])
        setReservation(response.data[0]);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching reservation:', error);
        setError('Failed to fetch reservation. Please try again.');
        setLoading(false);
      }
    };

    if (id && userId) {
      fetchReservation();
      console.log(reservation)
    }
  }, [id, userId]);


  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header />
      <Box display="flex" flexGrow={1}>
        <Sidebar />
        <Box flexGrow={1} p={3}>
          <DetailedReservation reservation={reservation} />
        </Box>
      </Box>
    </Box>
  );
};

export default DetailedReservationPage;