// import React from 'react';

// const UserContext = React.createContext();

// export default UserContext;

import React from 'react';

const UserContext = React.createContext({
  user: null,
  setUser: () => {},
  isSidebarOpen: false,
  handleToggleSidebar: () => {},
  handleCloseSidebar: () => {},
  isAdmin: false,
  listings: [],
  setListings: () =>{}
});

export default UserContext;