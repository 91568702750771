import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

import UserContext from '../UserContext';
import AllReservationsComponent from '../components/AllReservationsComponent';


const AllReservations = () => {
    const { user } = useContext(UserContext);


    return (
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Header />
        <Box display="flex" flexGrow={1}>
          <Sidebar />
          <Box flexGrow={1} p={3}>
            <AllReservationsComponent />
          </Box>
        </Box>
      </Box>
    );
};

export default AllReservations;



