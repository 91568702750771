

import React, { useState, useEffect } from 'react';
import { Container, Grid, Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Avatar } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, CategoryScale } from 'chart.js';
import { AiOutlineHome } from 'react-icons/ai';

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale);

const channelMapping = {
  'bookingCom': {
    reviewer: 'reviewer.name',
    content: 'content.positive',
    score: 'scoring.review_score',
    staff: 'scoring.staff',
    facilities: 'scoring.facilities',
    clean: 'scoring.clean',
    comfort: 'scoring.comfort',
    location: 'scoring.location',
    value: 'scoring.value',
  },
  'airbnb2': {
    reviewer: 'reviewer_role',
    content: 'public_review',
    score: 'overall_rating',
    staff: 'category_ratings_communication',
    facilities: 'category_ratings_checkin',
    clean: 'category_ratings_cleanliness',
    comfort: 'category_ratings_accuracy',
    location: 'category_ratings_location',
    value: 'category_ratings_value',
  },
};

const getNestedValue = (obj, path) => {
  if (!path) return 0;
  const keys = path.split('.');
  return keys.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : 0), obj);
};

const ReviewsPage = ({ reviewsData }) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);



  const calculateMetrics = (reviews) => {
    if (!reviews || reviews.length === 0) return null;
  
    const metrics = {
      overallScore: { sum: 0, count: 0 },
      reviewCount: 0,  // Initialize to 0
      communication: { sum: 0, count: 0 },
      checkIn: { sum: 0, count: 0 },
      cleaning: { sum: 0, count: 0 },
      accuracy: { sum: 0, count: 0 },
      location: { sum: 0, count: 0 },
      value: { sum: 0, count: 0 },
    };
  
    reviews.forEach((review) => {
      const mapping = channelMapping[review.channelId];
      if (!mapping) {
        console.log('No matching mapping found for review:', review);
        return;
      }
  
      // Increment review count for each valid review
      metrics.reviewCount++;
  
      const addMetric = (metricName, path) => {
        const value = getNestedValue(review.rawReview, path);
        if (value !== undefined && value !== null) {
          metrics[metricName].sum += normalizeScore(value, review.channelId);
          metrics[metricName].count++;
        }
      };
  
      addMetric('overallScore', mapping.score);
      addMetric('communication', mapping.staff);
      addMetric('checkIn', mapping.facilities);
      addMetric('cleaning', mapping.clean);
      addMetric('accuracy', mapping.comfort);
      addMetric('location', mapping.location);
      addMetric('value', mapping.value);
    });
  
    // Calculate averages
    Object.keys(metrics).forEach((key) => {
      if (key !== 'reviewCount') {  // Skip reviewCount
        if (metrics[key].count > 0) {
          metrics[key] = metrics[key].sum / metrics[key].count;
        } else {
          metrics[key] = 0;
        }
      }
    });
  
    return metrics;
  };
  const normalizeScore = (score, channelId) => {
    // Normalize scores to a 0-10 scale
    switch (channelId) {
      case 'airbnb2':
        // Airbnb uses a 0-5 scale
        return (score / 5) * 10;
      case 'bookingCom':
        // Booking.com already uses a 0-10 scale
        return score;
      default:
        console.warn(`Unknown channel: ${channelId}. Returning original score.`);
        return score;
    }
  };

  const metrics = calculateMetrics(reviewsData);

  if (!metrics) {
    return <Typography>No reviews data available.</Typography>;
  }

  const handleRowClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const reviewTrendData = {
    labels: reviewsData.map(review => new Date(review.createdAt).toLocaleDateString()),
    datasets: [
      {
        label: 'Review Score',
        data: reviewsData.map(review => {
          const mapping = channelMapping[review.channelId];
          return mapping ? getNestedValue(review.rawReview, mapping.score) : 0;
        }),
        borderColor: 'green',
        fill: false,
      }
    ]
  };

  const reviewTrendOptions = {
    scales: {
      y: {
        beginAtZero: true,
        max: 11,
      },
    },
  };

  return (
    <Container sx={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{metrics.overallScore.toFixed(1)}</Typography>
            <Typography sx={{ fontSize: '14px', color: 'gray' }}>Overall Score</Typography>
            <Typography sx={{ fontSize: '14px', color: 'gray' }}>{metrics.reviewCount} Reviews Count</Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#f0f4ff' }}>
            <Typography sx={{ fontSize: '14px', color: 'gray' }}>Communication</Typography>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{metrics.communication.toFixed(2)}</Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#f0f4ff' }}>
            <Typography sx={{ fontSize: '14px', color: 'gray' }}>Check In</Typography>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{metrics.checkIn.toFixed(2)}</Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#f0f4ff' }}>
            <Typography sx={{ fontSize: '14px', color: 'gray' }}>Cleaning</Typography>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{metrics.cleaning.toFixed(2)}</Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#e8f5e9' }}>
            <Typography sx={{ fontSize: '14px', color: 'gray' }}>Accuracy</Typography>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{metrics.accuracy.toFixed(2)}</Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#e8f5e9' }}>
            <Typography sx={{ fontSize: '14px', color: 'gray' }}>Location</Typography>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{metrics.location.toFixed(2)}</Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#e8f5e9' }}>
            <Typography sx={{ fontSize: '14px', color: 'gray' }}>Value</Typography>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{metrics.value.toFixed(2)}</Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={5}>
          <Paper sx={{ padding: '20px' }}>
            <Typography variant="h6" sx={{ marginBottom: '20px' }}>Review Trend</Typography>
            <Line data={reviewTrendData} options={reviewTrendOptions} />
          </Paper>
        </Grid>

        <Grid item xs={12} md={7}>
          <Paper sx={{ padding: '20px' }}>
            <Typography variant="h6" sx={{ marginBottom: '20px' }}>Guest Reviews</Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Guest</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Channel</TableCell>
                    <TableCell>Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reviewsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((review, index) => {
                    const mapping = channelMapping[review.channelId];
                    if (mapping) {
                      return (
                        <React.Fragment key={index}>
                          <TableRow onClick={() => handleRowClick(index)} sx={{ cursor: 'pointer' }}>
                            <TableCell>{review.rawReview.reviewer?.name || review.rawReview[mapping.reviewer]}</TableCell>
                            <TableCell>{new Date(review.createdAt).toLocaleDateString()}</TableCell>
                            <TableCell>{review.channelId}</TableCell>
                            <TableCell>{review.rawReview.scoring?.review_score || review.rawReview[mapping.score]}</TableCell>
                          </TableRow>
                          {expandedRow === index && (
                            <TableRow>
                              <TableCell colSpan={4} sx={{ textAlign: 'center', backgroundColor: '#f9f9f9' }}>
                                <Typography>{review.rawReview.content?.positive || review.rawReview[mapping.content]}</Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      );
                    } else {
                      console.log('No matching mapping found for review:', review);
                      return null;
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={reviewsData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly', padding: '20px' }}>
            <Avatar variant="square" sx={{ width: 56, height: 56, backgroundColor: '#f2f2f2' }}>
              <AiOutlineHome size={30} />
            </Avatar>
            <Avatar variant="square" sx={{ width: 56, height: 56, backgroundColor: '#f2f2f2' }}>
              <AiOutlineHome size={30} />
            </Avatar>
            <Avatar variant="square" sx={{ width: 56, height: 56, backgroundColor: '#f2f2f2' }}>
              <AiOutlineHome size={30} />
            </Avatar>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ReviewsPage;