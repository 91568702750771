

import React, { useState, useEffect, useContext ,useCallback} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
  CircularProgress,
  Collapse,
  TextField,
  Button,
  Checkbox,
  Modal,
} from '@mui/material';
import UserContext from '../UserContext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import api from '../api';

const PaymentModal = ({ open, handleClose, amount, onConfirm }) => {
    return (
      <Modal open={open} onClose={handleClose}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography variant="h6" component="h2">
            Confirm Payment
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Total Amount: €{amount.toFixed(2)}
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleClose} sx={{ mr: 1 }}>Cancel</Button>
            <Button onClick={onConfirm} variant="contained">Confirm Payment</Button>
          </Box>
        </Box>
      </Modal>
    );
  };
  

  const ListingRow = ({ listing, reservations, formatDate, onPayAll, onPaySelected }) => {
    const [open, setOpen] = useState(false);
    const [selectedReservations, setSelectedReservations] = useState([]);
    const [payAllModalOpen, setPayAllModalOpen] = useState(false);
    const [paySelectedModalOpen, setPaySelectedModalOpen] = useState(false);
  
    const unpaidReservations = reservations.filter(res => !res.isPaid);
    const totalRevenue = unpaidReservations.reduce((sum, res) => sum + (res.money?.ownerRevenue || 0), 0);
  
    const handleSelectReservation = (reservationId) => {
      setSelectedReservations(prev => 
        prev.includes(reservationId)
          ? prev.filter(id => id !== reservationId)
          : [...prev, reservationId]
      );
    };
  
    const handlePayAll = () => {
      onPayAll(listing.id, unpaidReservations, totalRevenue);
      setPayAllModalOpen(false);
    };
  
    const handlePaySelected = () => {
      const selectedReservationsData = unpaidReservations.filter(res => selectedReservations.includes(res.id));
      const selectedTotalRevenue = selectedReservationsData.reduce((sum, res) => sum + (res.money?.ownerRevenue || 0), 0);
      onPaySelected(listing.id, selectedReservationsData, selectedTotalRevenue);
      setPaySelectedModalOpen(false);
    };
  
    return (
      <>
        <TableRow>
          <TableCell>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{listing.nickname || listing.id}</TableCell>
          <TableCell>{unpaidReservations.length}</TableCell>
          <TableCell>€{totalRevenue.toFixed(2)}</TableCell>
          <TableCell>
            <Button 
              variant="contained" 
              onClick={() => setPayAllModalOpen(true)}
              disabled={unpaidReservations.length === 0}
            >
              Pay All
            </Button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Reservations
                </Typography>
                <Button 
                  variant="contained" 
                  onClick={() => setPaySelectedModalOpen(true)}
                  disabled={selectedReservations.length === 0}
                  sx={{ mb: 2 }}
                >
                  Pay Selected
                </Button>
                <Table size="small" aria-label="reservations">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedReservations(unpaidReservations.map(r => r.id));
                            } else {
                              setSelectedReservations([]);
                            }
                          }}
                          checked={selectedReservations.length === unpaidReservations.length && unpaidReservations.length > 0}
                          indeterminate={selectedReservations.length > 0 && selectedReservations.length < unpaidReservations.length}
                          disabled={unpaidReservations.length === 0}
                        />
                      </TableCell>
                      <TableCell>Check-in</TableCell>
                      <TableCell>Check-out</TableCell>
                      <TableCell>Guest Name</TableCell>
                      <TableCell>Source</TableCell>
                      <TableCell>Confirmation Code</TableCell>
                      <TableCell>Reservation ID</TableCell>
                      <TableCell align="right">Owner Revenue</TableCell>
                      <TableCell align="right">Total Price</TableCell>
                      <TableCell>Fully Paid</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Payment ID</TableCell>
                      <TableCell>Owner Paid</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reservations.map((reservation) => (
                      <TableRow key={reservation.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedReservations.includes(reservation.id)}
                            onChange={() => handleSelectReservation(reservation.id)}
                            disabled={reservation.isPaid}
                          />
                        </TableCell>
                        <TableCell>{formatDate(reservation.checkIn)}</TableCell>
                        <TableCell>{formatDate(reservation.checkOut)}</TableCell>
                        <TableCell>{reservation.guest?.fullName || 'N/A'}</TableCell>
                        <TableCell>{reservation.source || 'N/A'}</TableCell>
                        <TableCell>{reservation.confirmationCode || 'N/A'}</TableCell>
                        <TableCell>{reservation.reservationId || 'N/A'}</TableCell>
                        <TableCell align="right">€{reservation.money?.ownerRevenue?.toFixed(2) || '0.00'}</TableCell>
                        <TableCell align="right">€{reservation.money?.totalPaid?.toFixed(2) || '0.00'}</TableCell>
                        <TableCell>{reservation.money?.isFullyPaid ? 'Yes' : 'No'}</TableCell>
                        <TableCell>{reservation.status}</TableCell>
                        <TableCell>{reservation.paymentId || 'N/A'}</TableCell>
                        <TableCell>{reservation.isPaid ? 'Yes' : 'No'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
        <PaymentModal
          open={payAllModalOpen}
          handleClose={() => setPayAllModalOpen(false)}
          amount={totalRevenue}
          onConfirm={handlePayAll}
        />
        <PaymentModal
          open={paySelectedModalOpen}
          handleClose={() => setPaySelectedModalOpen(false)}
          amount={unpaidReservations.filter(res => selectedReservations.includes(res.id)).reduce((sum, res) => sum + (res.money?.ownerRevenue || 0), 0)}
          onConfirm={handlePaySelected}
        />
      </>
    );
  };

const AllReservationsComponent = () => {
  const { listings } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [listingsWithReservations, setListingsWithReservations] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [shouldFetch, setShouldFetch] = useState(false);




  useEffect(() => {
    const today = new Date();
    const prevFriday = new Date(today.setDate(today.getDate() - ((today.getDay() + 2) % 7)));
    const nextFriday = new Date(prevFriday);
    nextFriday.setDate(nextFriday.getDate() + 7);
    
    setStartDate(formatDateForInput(prevFriday));
    setEndDate(formatDateForInput(nextFriday));
  }, []);

  const fetchReservations = useCallback(async () => {
    if (!listings || listings.length === 0) {
      console.log('Listings not available yet, skipping fetch');
      return;
    }
  
    setLoading(true);
    setError(null);
  
    try {
      const response = await api.get('/api/date-range', {
        params: { startDate, endDate, timestamp: Date.now() }, // Add timestamp to prevent caching
      });
      console.log('Raw response data:', response.data.data);
      if (response.data.success) {
        const filteredReservations = filterReservationsInRange(response.data.data);
        console.log('Filtered reservations:', filteredReservations);
        const groupedReservations = groupReservationsByListing(filteredReservations);
        console.log('Grouped reservations:', groupedReservations);
        setListingsWithReservations(groupedReservations);
      } else {
        setError(response.data.message || 'An error occurred while fetching reservations');
      }
    } catch (err) {
      console.error('Error fetching reservations:', err);
      setError('An error occurred while fetching reservations');
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate, listings]);

  // const fetchReservations = useCallback(async () => {
  //   if (!listings || listings.length === 0) {
  //     console.log('Listings not available yet, skipping fetch');
  //     return;
  //   }

  //   setLoading(true);
  //   setError(null);

  //   try {
  //     const response = await api.get('/api/date-range', {
  //       params: { startDate, endDate },
  //     });
  //     if (response.data.success) {
  //       const filteredReservations = filterReservationsInRange(response.data.data);
  //       console.log('Filtered reservations:', filteredReservations);
  //       const groupedReservations = groupReservationsByListing(filteredReservations);
  //       console.log('Grouped reservations:', groupedReservations);
  //       setListingsWithReservations(groupedReservations);
  //     } else {
  //       setError(response.data.message || 'An error occurred while fetching reservations');
  //     }
  //   } catch (err) {
  //     console.error('Error fetching reservations:', err);
  //     setError('An error occurred while fetching reservations');
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [startDate, endDate, listings]);

  useEffect(() => {
    if (startDate && endDate && listings && listings.length > 0) {
      fetchReservations();
    }
  }, [startDate, endDate, listings, fetchReservations]);

  const formatDateForInput = (date) => {
    return date.toISOString().split('T')[0];
  };

  // const filterReservationsInRange = (reservations) => {
  //   const start = new Date(startDate).getTime();
  //   const end = new Date(endDate).getTime();
  //   return reservations.filter(reservation => {
  //     const checkIn = new Date(reservation.checkIn).getTime();
  //     const checkOut = new Date(reservation.checkOut).getTime();
  //     return (checkIn >= start && checkIn < end) || (checkOut > start && checkOut <= end) || (checkIn <= start && checkOut >= end);
  //   });
  // };

  const filterReservationsInRange = (reservations) => {
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    return reservations.filter(reservation => {
      const checkIn = new Date(reservation.checkIn).getTime();
      const checkOut = new Date(reservation.checkOut).getTime();
      return (
        reservation.status !== 'inquiry' && // Exclude inquiries
        ((checkIn >= start && checkIn < end) || 
         (checkOut > start && checkOut <= end) || 
         (checkIn <= start && checkOut >= end))
      );
    });
  };

  const groupReservationsByListing = (reservations) => {
    console.log('Listings from context:', listings);
    const grouped = reservations.reduce((acc, reservation) => {
      if (!acc[reservation.listingId]) {
        const listingDetails = listings.find(l => l._id === reservation.listingId) || {};
        console.log('Listing details for', reservation.listingId, ':', listingDetails);
        acc[reservation.listingId] = { 
          id: reservation.listingId, 
          nickname: listingDetails.nickname || listingDetails.title || 'Unknown',
          reservations: [] 
        };
      }
      acc[reservation.listingId].reservations.push(reservation);
      return acc;
    }, {});
    return Object.values(grouped);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  // const handlePayAll = async (listingId, reservations, totalAmount) => {
  //   try {
  //     const paymentId = `PAY-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  //     const paymentData = {
  //       paymentId,
  //       listingId,
  //       reservations: reservations.map(res => ({
  //         reservationId: res.id,
  //         amount: res.money.totalPaid,
  //         checkinDate: res.checkIn
  //         // Add any other relevant reservation details
  //       })),
  //       totalAmount,
  //       paymentDate: new Date().toISOString(),
  //     };

  //     // Send payment data to the backend
  //     //await api.post('/api/payments', paymentData);
  //         // Send payment data to the backend
  //       const response = await api.post('/api/payments', paymentData);

  //       if (response.data.success) {
  //         console.log('Payment processed successfully:', response.data);
  //         // Refresh reservations after payment
  //         fetchReservations();
  //       } else {
  //         throw new Error(response.data.message || 'Failed to process payment');
  //       }


  //     // Refresh reservations after payment
  //     fetchReservations();
  //   } catch (error) {
  //     console.error('Error processing payment:', error);
  //     setError('Failed to process payment. Please try again.');
  //   }
  // };

  // const handlePaySelected = async (listingId, selectedReservations, totalAmount) => {
  //   try {
  //     const paymentId = `PAY-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  //     const paymentData = {
  //       paymentId,
  //       listingId,
  //       reservations: selectedReservations.map(res => ({
  //         reservationId: res.id,
  //         amount: res.money.ownerRevenue,
  //         checkinDate: res.checkIn
  //         // Add any other relevant reservation details
  //       })),
  //       totalAmount,
  //       paymentDate: new Date().toISOString(),
  //     };

  //     // Send payment data to the backend
  //     //await api.post('/api/payments', paymentData);

  //     // Refresh reservations after payment
  //     fetchReservations();
  //   } catch (error) {
  //     console.error('Error processing payment:', error);
  //     setError('Failed to process payment. Please try again.');
  //   }
  // };

  const handlePayAll = async (listingId, reservations, totalAmount) => {
    try {
      const paymentId = `PAY-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
      // const paymentData = {
      //   paymentId,
      //   listingId,
      //   reservations: reservations.filter(res => !res.isPaid).map(res => ({
      //     reservationId: res.id,
      //     amount: res.money.ownerRevenue,
      //     checkinDate: res.checkIn
      //   })),
      //   totalAmount,
      //   paymentDate: new Date().toISOString(),
      // };
      const paymentData = {
        paymentId,
        listingId,
        reservations: reservations.filter(res => !res.isPaid && res.status !== 'inquiry').map(res => ({
          reservationId: res.id,
          amount: res.money.ownerRevenue,
          checkinDate: res.checkIn
        })),
        totalAmount,
        paymentDate: new Date().toISOString(),
      };
  
      const response = await api.post('/api/payments', paymentData);
  
      if (response.data.success) {
        console.log('Payment processed successfully:', response.data);
        await fetchReservations();
      } else {
        throw new Error(response.data.message || 'Failed to process payment');
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      setError('Failed to process payment. Please try again.');
    }
  };
  
  const handlePaySelected = async (listingId, selectedReservations, totalAmount) => {
    try {
      const paymentId = `PAY-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
      // const paymentData = {
      //   paymentId,
      //   listingId,
      //   reservations: selectedReservations.filter(res => !res.isPaid).map(res => ({
      //     reservationId: res.id,
      //     amount: res.money.ownerRevenue,
      //     checkinDate: res.checkIn
      //   })),
      //   totalAmount,
      //   paymentDate: new Date().toISOString(),
      // };
      const paymentData = {
        paymentId,
        listingId,
        reservations: selectedReservations.filter(res => !res.isPaid && res.status !== 'inquiry').map(res => ({
          reservationId: res.id,
          amount: res.money.ownerRevenue,
          checkinDate: res.checkIn
        })),
        totalAmount,
        paymentDate: new Date().toISOString(),
      };
  
      const response = await api.post('/api/payments', paymentData);
  
      if (response.data.success) {
        console.log('Payment processed successfully:', response.data);
        await fetchReservations();
      } else {
        throw new Error(response.data.message || 'Failed to process payment');
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      setError('Failed to process payment. Please try again.');
    }
  };



  return (
    <Box sx={{ width: '100%', overflowX: 'auto', p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Reservations
      </Typography>
      
      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <Button variant="contained" onClick={fetchReservations}>
          Fetch Reservations
        </Button>
      </Box>

      {loading && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}

      {listingsWithReservations.length > 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Listing</TableCell>
                <TableCell>Reservations Count</TableCell>
                <TableCell>Total Revenue</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listingsWithReservations.map((listing) => (
                <ListingRow 
                  key={listing.id} 
                  listing={listing} 
                  reservations={listing.reservations} 
                  formatDate={formatDate}
                  onPayAll={handlePayAll}
                  onPaySelected={handlePaySelected}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        !loading && <Typography>No reservations found for the selected date range.</Typography>
      )}


    </Box>
  );
};

export default AllReservationsComponent;