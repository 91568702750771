
// // import React from 'react';
// // import {
// //   Dialog,
// //   DialogTitle,
// //   DialogContent,
// //   Typography,
// //   IconButton,
// //   Box,
// //   Divider,
// //   Button,
// //   Chip,
// //   Grid,
// //   Card,
// //   CardContent,
// // } from '@mui/material';
// // import CloseIcon from '@mui/icons-material/Close';
// // import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// // import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// // import { styled } from '@mui/system';

// // const StyledDialogTitle = styled(DialogTitle)({
// //   backgroundColor: '#1976d2',
// //   color: '#ffffff',
// //   display: 'flex',
// //   alignItems: 'center',
// //   justifyContent: 'space-between',
// //   '& .MuiTypography-root': {
// //     fontSize: '1.25rem',
// //     fontWeight: 'bold',
// //   },
// // });

// // const StyledDialogContent = styled(DialogContent)({
// //   padding: '16px',
// // });

// // const SectionTitle = styled(Typography)({
// //   fontSize: '1rem',
// //   fontWeight: 'bold',
// //   marginBottom: '8px',
// //   color: '#6c757d',
// //   display: 'flex',
// //   alignItems: 'center',
// //   cursor: 'pointer',
// // });

// // const GreyText = styled(Typography)({
// //   color: '#6c757d',
// //   fontWeight: 'bold',
// // });

// // const ReservationModal = ({ reservation, onClose }) => {
// //   //console.log(reservation)
// //   const processing_fee = reservation.money.subTotalPrice *0.035
// //   const firstThreeDigits = Number(processing_fee.toPrecision(5));

// //   const statusChipProps = reservation.status.toLowerCase() === 'confirmed'
// //   ? { label: "confirmed", sx: { backgroundColor: 'green', color: 'white' } }
// //   : { label: "cancelled", sx: { backgroundColor: 'red', color: 'white' } };

// //   //console.log(firstThreeDigits)
// //   return (
// //     <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
// //       <StyledDialogTitle>
// //         <Box display="flex" alignItems="center">
// //           <Typography>Reservation</Typography>
// //           <IconButton edge="end" color="inherit" component="a" href={`/reservations/${reservation._id}`} target="_blank">
// //             <OpenInNewIcon />
// //           </IconButton>
// //         </Box>
// //         <IconButton edge="end" color="inherit" onClick={onClose}>
// //           <CloseIcon />
// //         </IconButton>
// //       </StyledDialogTitle>
// //       <StyledDialogContent>
// //         <Box mb={3}>
// //           <Typography variant="h6" component="div" display="flex" alignItems="center" fontWeight="medium" textTransform="capitalize">
// //             {reservation.guest.fullName}
// //             <Chip 
// //               label={statusChipProps.label} 
// //               size="small" 
// //               sx={{ ...statusChipProps.sx, mx: 3 }} 
// //             />
            
// //           </Typography>
// //         </Box>
// //         <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
// //           <Box p={1}>
// //             <GreyText variant="caption" textTransform="capitalize">check-in</GreyText>
// //             <Typography variant="body1" fontWeight="medium">{reservation.checkIn.split('T')[0]}</Typography>
// //           </Box>
// //           <Box p={1}>
// //             <GreyText variant="caption" textTransform="capitalize">check-out</GreyText>
// //             <Typography variant="body1" fontWeight="medium">{reservation.checkOut.split('T')[0]}</Typography>
// //           </Box>
// //           <Box p={1}>
// //             <GreyText variant="caption" textTransform="capitalize">Confirmation Code</GreyText>
// //             <Typography variant="body1" fontWeight="medium">{reservation.confirmationCode}</Typography>          </Box>
// //         </Box>
// //         <Box my={3}>
// //           <SectionTitle>
// //             <ExpandMoreIcon sx={{ fontSize: '12px', mr: 2 }} />
// //             Details
// //           </SectionTitle>
// //           <Box py={2} px={4}>
// //             <Grid container spacing={2} alignItems="baseline">
// //               <Grid item xs={12} sm={4}>
// //                 <GreyText>Guest Name:</GreyText>
// //               </Grid>
// //               <Grid item xs={12} sm={6}>
// //                 <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">{reservation.guest.fullName}</Typography>
// //               </Grid>
// //               <Grid item xs={12} sm={4}>
// //                 <GreyText>Guest Email:</GreyText>
// //               </Grid>
// //               <Grid item xs={12} sm={6}>
// //                 <Typography variant="subtitle1" fontWeight="medium">{reservation.guest.email}</Typography>
// //               </Grid>
// //               <Grid item xs={12} sm={4}>
// //                 <GreyText>Guest Phone:</GreyText>
// //               </Grid>
// //               <Grid item xs={12} sm={6}>
// //                 <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">{reservation.guest.phone}</Typography>
// //               </Grid>
// //             </Grid>
// //             <Divider sx={{ my: 2 }} />
// //             <Grid container spacing={2} alignItems="baseline">
// //               <Grid item xs={12} sm={4}>
// //                 <GreyText>Source</GreyText>
// //               </Grid>
// //               <Grid item xs={12} sm={6}>
// //                 <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">{reservation.source}</Typography>
// //               </Grid>
// //             </Grid>
// //           </Box>
// //           <SectionTitle>
// //             <ExpandMoreIcon sx={{ fontSize: '12px', mr: 2 }} />
// //             Finance
// //           </SectionTitle>
// //           <Box>
// //             <Grid container spacing={2}>
// //               <Grid item xs={12} sm={6}>
// //                 <Card sx={{ height: '85px', minWidth: '100%' }}>
// //                   <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
// //                     <Box sx={{ backgroundColor: 'success.main', width: '6px', height: '100%', mr: 2 }}></Box>
// //                     <Box>
// //                       <Typography variant="body2" color="text.secondary" fontWeight="medium" textTransform="capitalize">
// //                         Total Reservation Payment
// //                       </Typography>
// //                       <Typography variant="h6" fontWeight="medium">€{reservation.money.hostPayout}</Typography>
// //                     </Box>
// //                   </CardContent>
// //                 </Card>
// //               </Grid>
// //               <Grid item xs={12} sm={6}>
// //                 <Card sx={{ height: '85px', minWidth: '100%' }}>
// //                   <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
// //                     <Box sx={{ backgroundColor: 'orange', width: '6px', height: '100%', mr: 2 }}></Box>
// //                     <Box>
// //                       <Typography variant="body2" color="text.secondary" fontWeight="medium" textTransform="capitalize">
// //                         Owner Revenue
// //                       </Typography>
// //                       <Typography variant="h6" fontWeight="medium">€{reservation.money.ownerRevenue}</Typography>
// //                     </Box>
// //                   </CardContent>
// //                 </Card>
// //               </Grid>
// //             </Grid>
// //             <Grid container spacing={2} alignItems="baseline">
// //               <Grid item xs={12} sm={4}>
// //                 <GreyText>Accommodation</GreyText>
// //               </Grid>
// //               <Grid item xs={12} sm={6}>
// //                 <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">€{reservation.money.fareAccommodationAdjusted}</Typography>
// //               </Grid>
// //               <Grid item xs={12} sm={4}>
// //                 <GreyText>Cleaning</GreyText>
// //               </Grid>
// //               <Grid item xs={12} sm={6}>
// //                 <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">€{reservation.money.fareCleaning}</Typography>
// //               </Grid>
// //               <Grid item xs={12} sm={4}>
// //                 <GreyText>Taxes</GreyText>
// //               </Grid>
// //               <Grid item xs={12} sm={6}>
// //                 <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">€{reservation.money.totalTaxes}</Typography>
// //               </Grid>
// //               <Grid item xs={12} sm={4}>
// //                 <GreyText>Channel fee</GreyText>
// //               </Grid>
// //               <Grid item xs={12} sm={6}>
// //                 <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">€{reservation.money.hostServiceFee}</Typography>
// //               </Grid>
// //               <Grid item xs={12} sm={4}>
// //                 <GreyText>Processing fee</GreyText>
// //               </Grid>
// //               <Grid item xs={12} sm={6}>
// //                 <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">€{firstThreeDigits}</Typography>
// //               </Grid>
// //               <Grid item xs={12} sm={4}>
// //                 <GreyText>Management fee</GreyText>
// //               </Grid>
// //               <Grid item xs={12} sm={6}>
// //                 <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">€{reservation.money.commission}</Typography>
// //               </Grid>
// //               <Grid item xs={12} sm={4}>
// //                 <GreyText>Net income</GreyText>
// //               </Grid>
// //               <Grid item xs={12} sm={6}>
// //                 <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">€{reservation.money.netIncome}</Typography>
// //               </Grid>
// //             </Grid>
// //           </Box>
// //         </Box>
// //       </StyledDialogContent>
// //     </Dialog>
// //   );
// // };

// // export default ReservationModal;


// import React from 'react';
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   Typography,
//   IconButton,
//   Box,
//   Divider,
//   Button,
//   Chip,
//   Grid,
//   Card,
//   CardContent,
// } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { styled } from '@mui/system';

// const StyledDialogTitle = styled(DialogTitle)({
//   backgroundColor: '#1976d2',
//   color: '#ffffff',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'space-between',
//   '& .MuiTypography-root': {
//     fontSize: '1.25rem',
//     fontWeight: 'bold',
//   },
// });

// const StyledDialogContent = styled(DialogContent)({
//   padding: '16px',
// });

// const SectionTitle = styled(Typography)({
//   fontSize: '1rem',
//   fontWeight: 'bold',
//   marginBottom: '8px',
//   color: '#6c757d',
//   display: 'flex',
//   alignItems: 'center',
//   cursor: 'pointer',
// });

// const GreyText = styled(Typography)({
//   color: '#6c757d',
//   fontWeight: 'bold',
// });

// const ReservationModal = ({ reservation, onClose }) => {
//   const processing_fee = reservation?.money?.subTotalPrice ? reservation.money.subTotalPrice * 0.035 : 0;
//   const firstThreeDigits = Number(processing_fee.toPrecision(5));

//   const statusChipProps = reservation?.status?.toLowerCase() === 'confirmed'
//     ? { label: "confirmed", sx: { backgroundColor: 'green', color: 'white' } }
//     : { label: "cancelled", sx: { backgroundColor: 'red', color: 'white' } };

//   return (
//     <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
//       <StyledDialogTitle>
//         <Box display="flex" alignItems="center">
//           <Typography>Reservation</Typography>
//           <IconButton edge="end" color="inherit" component="a" href={`/reservations/${reservation?._id}`} target="_blank">
//             <OpenInNewIcon />
//           </IconButton>
//         </Box>
//         <IconButton edge="end" color="inherit" onClick={onClose}>
//           <CloseIcon />
//         </IconButton>
//       </StyledDialogTitle>
//       <StyledDialogContent>
//         <Box mb={3}>
//           <Typography variant="h6" component="div" display="flex" alignItems="center" fontWeight="medium" textTransform="capitalize">
//             {reservation?.guest?.fullName || ''}
//             <Chip
//               label={statusChipProps.label}
//               size="small"
//               sx={{ ...statusChipProps.sx, mx: 3 }}
//             />
//           </Typography>
//         </Box>
//         <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
//           <Box p={1}>
//             <GreyText variant="caption" textTransform="capitalize">check-in</GreyText>
//             <Typography variant="body1" fontWeight="medium">{reservation?.checkIn?.split('T')[0] || ''}</Typography>
//           </Box>
//           <Box p={1}>
//             <GreyText variant="caption" textTransform="capitalize">check-out</GreyText>
//             <Typography variant="body1" fontWeight="medium">{reservation?.checkOut?.split('T')[0] || ''}</Typography>
//           </Box>
//           <Box p={1}>
//             <GreyText variant="caption" textTransform="capitalize">Confirmation Code</GreyText>
//             <Typography variant="body1" fontWeight="medium">{reservation?.confirmationCode || ''}</Typography>
//           </Box>
//         </Box>
//         <Box my={3}>
//           <SectionTitle>
//             <ExpandMoreIcon sx={{ fontSize: '12px', mr: 2 }} />
//             Details
//           </SectionTitle>
//           <Box py={2} px={4}>
//             <Grid container spacing={2} alignItems="baseline">
//               <Grid item xs={12} sm={4}>
//                 <GreyText>Guest Name:</GreyText>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">{reservation?.guest?.fullName || ''}</Typography>
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <GreyText>Guest Email:</GreyText>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <Typography variant="subtitle1" fontWeight="medium">{reservation?.guest?.email || ''}</Typography>
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <GreyText>Guest Phone:</GreyText>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">{reservation?.guest?.phone || ''}</Typography>
//               </Grid>
//             </Grid>
//             <Divider sx={{ my: 2 }} />
//             <Grid container spacing={2} alignItems="baseline">
//               <Grid item xs={12} sm={4}>
//                 <GreyText>Source</GreyText>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">{reservation?.source || ''}</Typography>
//               </Grid>
//             </Grid>
//           </Box>
//           <SectionTitle>
//             <ExpandMoreIcon sx={{ fontSize: '12px', mr: 2 }} />
//             Finance
//           </SectionTitle>
//           <Box>
//             <Grid container spacing={2}>
//               <Grid item xs={12} sm={6}>
//                 <Card sx={{ height: '85px', minWidth: '100%' }}>
//                   <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
//                     <Box sx={{ backgroundColor: 'success.main', width: '6px', height: '100%', mr: 2 }}></Box>
//                     <Box>
//                       <Typography variant="body2" color="text.secondary" fontWeight="medium" textTransform="capitalize">
//                         Total Reservation Payment
//                       </Typography>
//                       <Typography variant="h6" fontWeight="medium">€{reservation?.money?.hostPayout || ''}</Typography>
//                     </Box>
//                   </CardContent>
//                 </Card>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <Card sx={{ height: '85px', minWidth: '100%' }}>
//                   <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
//                     <Box sx={{ backgroundColor: 'orange', width: '6px', height: '100%', mr: 2 }}></Box>
//                     <Box>
//                       <Typography variant="body2" color="text.secondary" fontWeight="medium" textTransform="capitalize">
//                         Owner Revenue
//                       </Typography>
//                       <Typography variant="h6" fontWeight="medium">€{reservation?.money?.ownerRevenue || ''}</Typography>
//                     </Box>
//                   </CardContent>
//                 </Card>
//               </Grid>
//             </Grid>
//             <Grid container spacing={2} alignItems="baseline">
//               <Grid item xs={12} sm={4}>
//                 <GreyText>Accommodation</GreyText>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">€{reservation?.money?.fareAccommodationAdjusted || ''}</Typography>
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <GreyText>Cleaning</GreyText>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">€{reservation?.money?.fareCleaning || ''}</Typography>
//               </Grid>
//               {/* <Grid item xs={12} sm={4}>
//                 <GreyText>Taxes</GreyText>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">€{reservation?.money?.totalTaxes || ''}</Typography>
//               </Grid> */}
//               <Grid item xs={12} sm={4}>
//                 <GreyText>Channel fee</GreyText>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">€{reservation?.money?.hostServiceFee || ''}</Typography>
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <GreyText>Processing fee</GreyText>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">€{firstThreeDigits}</Typography>
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <GreyText>Management fee</GreyText>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">€{reservation?.money?.commission || ''}</Typography>
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <GreyText>Net income</GreyText>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">€{reservation?.money?.netIncome || ''}</Typography>
//               </Grid>
//             </Grid>
//           </Box>
//         </Box>
//       </StyledDialogContent>
//     </Dialog>
//   );
// };

// export default ReservationModal;

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Box,
  Divider,
  Chip,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';

const StyledDialogTitle = styled(DialogTitle)({
  backgroundColor: '#1976d2',
  color: '#ffffff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& .MuiTypography-root': {
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
});

const StyledDialogContent = styled(DialogContent)({
  padding: '16px',
});

const SectionTitle = styled(Typography)({
  fontSize: '1rem',
  fontWeight: 'bold',
  marginBottom: '8px',
  color: '#6c757d',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

const GreyText = styled(Typography)({
  color: '#6c757d',
  fontWeight: 'bold',
});

const ReservationModal = ({ reservation, onClose }) => {
  const isCancelledButPaid = reservation?.status === 'canceled' &&
    reservation?.customFields &&
    reservation?.customFields.some(field => field.fieldId === '5bcb9c8e4ffd0c00388f5641');

  const money = isCancelledButPaid ? reservation?.preCancelationMoney : reservation?.money;

  const processing_fee = money?.subTotalPrice ? money.subTotalPrice * 0.035 : 0;
  const firstThreeDigits = Number(processing_fee.toPrecision(5));

  const statusChipProps = reservation?.status?.toLowerCase() === 'confirmed'
    ? { label: "confirmed", sx: { backgroundColor: 'green', color: 'white' } }
    : { label: "cancelled", sx: { backgroundColor: 'red', color: 'white' } };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
      <StyledDialogTitle>
        <Box display="flex" alignItems="center">
          <Typography>Reservation</Typography>
          <IconButton edge="end" color="inherit" component="a" href={`/reservations/${reservation?._id}`} target="_blank">
            <OpenInNewIcon />
          </IconButton>
        </Box>
        <IconButton edge="end" color="inherit" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Box mb={3}>
          <Typography variant="h6" component="div" display="flex" alignItems="center" fontWeight="medium" textTransform="capitalize">
            {reservation?.guest?.fullName || ''}
            <Chip
              label={statusChipProps.label}
              size="small"
              sx={{ ...statusChipProps.sx, mx: 3 }}
            />
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
          <Box p={1}>
            <GreyText variant="caption" textTransform="capitalize">check-in</GreyText>
            <Typography variant="body1" fontWeight="medium">{reservation?.checkIn?.split('T')[0] || ''}</Typography>
          </Box>
          <Box p={1}>
            <GreyText variant="caption" textTransform="capitalize">check-out</GreyText>
            <Typography variant="body1" fontWeight="medium">{reservation?.checkOut?.split('T')[0] || ''}</Typography>
          </Box>
          <Box p={1}>
            <GreyText variant="caption" textTransform="capitalize">Confirmation Code</GreyText>
            <Typography variant="body1" fontWeight="medium">{reservation?.confirmationCode || ''}</Typography>
          </Box>
        </Box>
        <Box my={3}>
          <SectionTitle>
            <ExpandMoreIcon sx={{ fontSize: '12px', mr: 2 }} />
            Details
          </SectionTitle>
          <Box py={2} px={4}>
            <Grid container spacing={2} alignItems="baseline">
              <Grid item xs={12} sm={4}>
                <GreyText>Guest Name:</GreyText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">{reservation?.guest?.fullName || ''}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <GreyText>Guest Email:</GreyText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" fontWeight="medium">{reservation?.guest?.email || ''}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <GreyText>Guest Phone:</GreyText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">{reservation?.guest?.phone || ''}</Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2} alignItems="baseline">
              <Grid item xs={12} sm={4}>
                <GreyText>Source</GreyText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">{reservation?.source || ''}</Typography>
              </Grid>
            </Grid>
          </Box>
          <SectionTitle>
            <ExpandMoreIcon sx={{ fontSize: '12px', mr: 2 }} />
            Finance
          </SectionTitle>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Card sx={{ height: '85px', minWidth: '100%' }}>
                  <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ backgroundColor: 'success.main', width: '6px', height: '100%', mr: 2 }}></Box>
                    <Box>
                      <Typography variant="body2" color="text.secondary" fontWeight="medium" textTransform="capitalize">
                        Total Reservation Payment
                      </Typography>
                      <Typography variant="h6" fontWeight="medium">€{money?.hostPayout || ''}</Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card sx={{ height: '85px', minWidth: '100%' }}>
                  <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ backgroundColor: 'orange', width: '6px', height: '100%', mr: 2 }}></Box>
                    <Box>
                      <Typography variant="body2" color="text.secondary" fontWeight="medium" textTransform="capitalize">
                        Owner Revenue
                      </Typography>
                      <Typography variant="h6" fontWeight="medium">€{money?.ownerRevenue || ''}</Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="baseline">
              {/* <Grid item xs={12} sm={4}>
                <GreyText>Accommodation</GreyText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">€{money?.fareAccommodationAdjusted || ''}</Typography>
              </Grid> */}
              <Grid item xs={12} sm={4}>
                <GreyText>Cleaning</GreyText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">€{money?.fareCleaning || ''}</Typography>
              </Grid>
              {/* <Grid item xs={12} sm={4}>
                <GreyText>Channel fee</GreyText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">€{money?.hostServiceFee || ''}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <GreyText>Processing fee</GreyText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">€{firstThreeDigits}</Typography>
              </Grid> */}
              <Grid item xs={12} sm={4}>
                <GreyText>Management fee</GreyText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">€{money?.commission || ''}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <GreyText>Net income</GreyText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" fontWeight="medium" textTransform="capitalize">€{money?.netIncome || ''}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </StyledDialogContent>
    </Dialog>
  );
};

export default ReservationModal;
