


// import React, { useState, useContext, useEffect } from 'react';
// import Sidebar from '../components/Sidebar';
// import Header from '../components/Header';
// import UserContext from '../UserContext';
// import {
//   AppBar, Toolbar, Typography, Container, Grid, Paper, Box, Tab, Tabs, IconButton,
//   Table, TableBody, TableCell, TableContainer, TableHead, TableRow
// } from '@mui/material';
// import {
//   BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, PieChart, Pie, Cell, ResponsiveContainer
// } from 'recharts';
// import api from '../api';
// import { ArrowBack, ArrowForward } from '@mui/icons-material';
// import LoadingScreen from '../components/LoadingScreen';



// const Dashboard = () => {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//   const { user } = useContext(UserContext);
//   const [stats, setStats] = useState({});
//   const [monthlyRevenueData, setMonthlyRevenueData] = useState([]);
//   const [occupancyData, setOccupancyData] = useState([]);
//   const [expensesData, setExpensesData] = useState([]);
//   const [listingsData, setListingsData] = useState([]);
//   const [value, setValue] = useState(0);
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         setIsLoading(true);
//         setError(null);
//         console.log("Fetching data for user ID:", user.id);
//         const response = await api.get(`/api/reservationstat/${user.id}`);
//         if (response.status === 200) {
//           console.log("Raw data received:", response.data);
//           calculateStats(response.data);
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         setError('Failed to fetch data. Please try again later.');
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchData();
//   }, [user.id]);

//   const calculateStats = (listingsData) => {
//     console.log("Calculating stats from:", listingsData);
//     let totalIncome = 0;
//     let totalExpenses = 0;
//     let totalOccupancy = 0;
//     let listingCount = 0;
//     const monthlyRevenue = {};
//     const monthlyOccupancy = {};
//     const expenseCategories = {};
//     const listingsInfo = [];
  
//     const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
//     months.forEach(month => {
//       monthlyRevenue[month] = 0;
//       monthlyOccupancy[month] = 0;
//     });
  
//     Object.entries(listingsData).forEach(([listingId, reservations]) => {
//       listingCount++;
//       let listingIncome = 0;
//       let listingExpenses = 0;
//       let listingOccupancy = 0;
//       const listingMonthlyOccupancy = {...monthlyOccupancy};
//       const listingMonthlyIncome = {...monthlyRevenue};
//       let totalNights = 0;
  
//       Object.values(reservations).forEach(reservation => {
//         const checkInDate = new Date(reservation.checkIn);
//         const checkOutDate = new Date(reservation.checkOut);
//         const currentYear = new Date().getFullYear();
//         const month = checkInDate.toLocaleString('default', { month: 'short' });
  
//         if (checkInDate.getFullYear() === currentYear) {
//           const income = reservation.money.fareAccommodationAdjusted || 0;
//           const expenses = (reservation.money.totalFees || 0) + 
//                            (reservation.money.commission || 0) + 
//                            (reservation.money.hostServiceFee || 0);
          
//           const nightsCount = (checkOutDate - checkInDate) / (1000 * 60 * 60 * 24);
  
//           totalIncome += income;
//           totalExpenses += expenses;
//           totalOccupancy += nightsCount;
  
//           listingIncome += income;
//           listingExpenses += expenses;
//           listingOccupancy += nightsCount;
  
//           monthlyRevenue[month] += income;
//           monthlyOccupancy[month] += nightsCount;
//           listingMonthlyOccupancy[month] += nightsCount;
//           listingMonthlyIncome[month] += income;
  
//           totalNights += nightsCount;
  
//           expenseCategories['Cleaning'] = (expenseCategories['Cleaning'] || 0) + (reservation.money.fareCleaning || 0);
//           expenseCategories['Commission'] = (expenseCategories['Commission'] || 0) + (reservation.money.commission || 0);
//           expenseCategories['Host Service Fee'] = (expenseCategories['Host Service Fee'] || 0) + (reservation.money.hostServiceFee || 0);
//         }
//       });
  
//       const listingName = reservations[Object.keys(reservations)[0]]?.listing?.title || `Listing ${listingId}`;
      
//       const totalDaysInYear = 365;
//       const occupancyRate = (totalNights / totalDaysInYear) * 100;
      
//       listingsInfo.push({
//         id: listingId,
//         name: listingName,
//         income: listingIncome,
//         expenses: listingExpenses,
//         occupancy: occupancyRate,
//         opsExpenseRatio: (listingExpenses / listingIncome) * 100,
//         monthlyOccupancy: listingMonthlyOccupancy,
//         monthlyIncome: listingMonthlyIncome,
//         totalNights: totalNights,
//         adr: totalNights > 0 ? listingIncome / totalNights : 0
//       });
  
//       console.log(`Listing ${listingName}:`, {
//         income: listingIncome.toFixed(2),
//         expenses: listingExpenses.toFixed(2),
//         occupancy: occupancyRate.toFixed(2) + '%',
//         opsExpenseRatio: ((listingExpenses / listingIncome) * 100).toFixed(2) + '%',
//         totalNights: totalNights,
//         adr: totalNights > 0 ? (listingIncome / totalNights).toFixed(2) : 'N/A'
//       });
//     });
  
//     const avgOccupancy = listingCount > 0 ? (totalOccupancy / (listingCount * 365)) * 100 : 0;
  
//     console.log("Calculated stats:", {
//       totalIncome,
//       totalExpenses,
//       avgOccupancy,
//       opsExpenseRatio: totalIncome > 0 ? (totalExpenses / totalIncome) * 100 : 0,
//       listingCount,
//       totalOccupancy
//     });
  
//     setStats({
//       totalIncome: totalIncome || 0,
//       totalExpenses: totalExpenses || 0,
//       avgOccupancy: avgOccupancy || 0,
//       opsExpenseRatio: totalIncome > 0 ? (totalExpenses / totalIncome) * 100 : 0,
//       listingCount: listingCount || 0
//     });
  
//     const sortedMonthlyRevenue = months.map(month => ({
//       name: month,
//       value: monthlyRevenue[month]
//     }));
  
//     setMonthlyRevenueData(sortedMonthlyRevenue || []);
//     setOccupancyData(months.map(month => ({ 
//       name: month, 
//       value: (monthlyOccupancy[month] / (listingCount * getDaysInMonth(month))) * 100 
//     })) || []);
//     setExpensesData(Object.entries(expenseCategories).map(([name, value]) => ({ name, value })) || []);
//     setListingsData(listingsInfo || []);
  
//     console.log("Monthly Revenue Data:", sortedMonthlyRevenue);
//     console.log("Occupancy Data:", occupancyData);
//     console.log("Expenses Data:", expensesData);
//     console.log("Listings Data:", listingsInfo);
//   };
  
//   const getDaysInMonth = (monthName) => {
//     const monthIndex = new Date(Date.parse(monthName + " 1, 2000")).getMonth();
//     return new Date(new Date().getFullYear(), monthIndex + 1, 0).getDate();
//   };

//   const handleToggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
//   const handleChange = (event, newValue) => setValue(newValue);

//   const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

//   if (isLoading) {
//     return <LoadingScreen />;
//   }

//   if (error) {
//     return (
//       <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
//         <Typography color="error">{error}</Typography>
//       </Box>
//     );
//   }

//   return (
//     <div className="dashboard">
//       <Header onToggleSidebar={handleToggleSidebar} />
//       <Sidebar />
//       <div className="main-content">
//         <Box sx={{ flexGrow: 1 }}>
//           <AppBar position="static" color="default" elevation={0}  sx={{ 
//     backgroundColor: 'red', // or use a specific shade of red
//     color: 'white' // This ensures the text is visible on the red background
//   }}>
//             <Toolbar>
//               <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
//                 BETA VERSION - MAY INCLUDE FALSE DATA 
//               </Typography>
//               <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                 <IconButton size="small">
//                   <ArrowBack />
//                 </IconButton>
//                 <Typography variant="body1" sx={{ mx: 1 }}>
//                   {new Date().getFullYear()}
//                 </Typography>
//                 <IconButton size="small">
//                   <ArrowForward />
//                 </IconButton>
//               </Box>
//             </Toolbar>
//           </AppBar>
//           <Container maxWidth="xl" sx={{ mt: 4 }}>
//             <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
//               <Tab label="Overview" />
//               <Tab label="Reviews" />
//             </Tabs>
//             <Grid container spacing={3} sx={{ mt: 2 }}>
//               <Grid item xs={12} md={3}>
//                 <Paper elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
//                   <Typography variant="subtitle2" gutterBottom>
//                     Ops Expense Ratio
//                   </Typography>
//                   <Typography variant="h4">{stats.opsExpenseRatio?.toFixed(2)}%</Typography>
//                 </Paper>
//               </Grid>
//               <Grid item xs={12} md={3}>
//                 <Paper elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
//                   <Typography variant="subtitle2" gutterBottom>
//                     Avg Occupancy
//                   </Typography>
//                   <Typography variant="h4">{stats.avgOccupancy?.toFixed(2)}%</Typography>
//                 </Paper>
//               </Grid>
//               <Grid item xs={12} md={3}>
//                 <Paper elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
//                   <Typography variant="subtitle2" gutterBottom>
//                     Total Income YTD
//                   </Typography>
//                   <Typography variant="h4">€{stats.totalIncome?.toFixed(2)}</Typography>
//                 </Paper>
//               </Grid>
//               <Grid item xs={12} md={3}>
//                 <Paper elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
//                   <Typography variant="subtitle2" gutterBottom>
//                     Total Expenses YTD
//                   </Typography>
//                   <Typography variant="h4">€{stats.totalExpenses?.toFixed(2)}</Typography>
//                 </Paper>
//               </Grid>
//             </Grid>
//             <Grid container spacing={3} sx={{ mt: 2 }}>
//               <Grid item xs={12} md={4}>
//                 <Paper elevation={0} sx={{ p: 2 }}>
//                   <Typography variant="subtitle2" gutterBottom>
//                     Number of Listings
//                   </Typography>
//                   <Typography variant="h4">{stats.listingCount}</Typography>
//                 </Paper>
//               </Grid>
//               {monthlyRevenueData.length > 0 && (
//                 <Grid item xs={12} md={8}>
//                   <Paper elevation={0} sx={{ p: 2 }}>
//                     <Typography variant="h6" gutterBottom>
//                       Monthly Revenue
//                     </Typography>
//                     <ResponsiveContainer width="100%" height={300}>
//                       <BarChart data={monthlyRevenueData}>
//                         <CartesianGrid strokeDasharray="3 3" />
//                         <XAxis dataKey="name" />
//                         <YAxis />
//                         <Tooltip />
//                         <Bar dataKey="value" fill="#8884d8" />
//                       </BarChart>
//                     </ResponsiveContainer>
//                   </Paper>
//                 </Grid>
//               )}
//             </Grid>
//             {listingsData.length > 0 && (
//               <Grid container spacing={3} sx={{ mt: 2 }}>
//                 <Grid item xs={12}>
//                   <TableContainer component={Paper} elevation={0}>
//                     <Table sx={{ minWidth: 650 }} aria-label="listings table">
//                       <TableHead>
//                         <TableRow>
//                           <TableCell>Listing Name</TableCell>
//                           <TableCell align="right">Income</TableCell>
//                           <TableCell align="right">Expenses</TableCell>
//                           <TableCell align="right">Occupancy</TableCell>
//                           <TableCell align="right">Ops Expense Ratio</TableCell>
//                           <TableCell align="right">Total Nights</TableCell>
//                           <TableCell align="right">ADR</TableCell>
//                         </TableRow>
//                       </TableHead>
//                       <TableBody>
//                         {listingsData.map((listing) => (
//                           <TableRow key={listing.id}>
//                             <TableCell component="th" scope="row">
//                               {listing.name}
//                             </TableCell>
//                             <TableCell align="right">€{listing.income.toFixed(2)}</TableCell>
//                             <TableCell align="right">€{listing.expenses.toFixed(2)}</TableCell>
//                             <TableCell align="right">{listing.occupancy.toFixed(2)}%</TableCell>
//                             <TableCell align="right">{listing.opsExpenseRatio.toFixed(2)}%</TableCell>
//                             <TableCell align="right">{listing.totalNights}</TableCell>
//                             <TableCell align="right">€{listing.adr.toFixed(2)}</TableCell>
//                           </TableRow>
//                         ))}
//                       </TableBody>
//                     </Table>
//                   </TableContainer>
//                 </Grid>
//               </Grid>
//             )}
//             <Grid container spacing={3} sx={{ mt: 2 }}>
//               {occupancyData.length > 0 && (
//                 <Grid item xs={12} md={6}>
//                   <Paper elevation={0} sx={{ p: 2 }}>
//                     <Typography variant="h6" gutterBottom>
//                       Occupancy
//                     </Typography>
//                     <ResponsiveContainer width="100%" height={300}>
//                       <LineChart data={occupancyData}>
//                         <CartesianGrid strokeDasharray="3 3" />
//                         <XAxis dataKey="name" />
//                         <YAxis />
//                         <Tooltip />
//                         <Line type="monotone" dataKey="value" stroke="#8884d8" />
//                       </LineChart>
//                     </ResponsiveContainer>
//                   </Paper>
//                 </Grid>
//               )}
//               {expensesData.length > 0 && (
//                 <Grid item xs={12} md={6}>
//                   <Paper elevation={0} sx={{ p: 2 }}>
//                     <Typography variant="h6" gutterBottom>
//                       Expenses Sources
//                     </Typography>
//                     <ResponsiveContainer width="100%" height={300}>
//                       <PieChart>
//                         <Pie
//                           data={expensesData}
//                           cx="50%"
//                           cy="50%"
//                           innerRadius={60}
//                           outerRadius={80}
//                           fill="#8884d8"
//                           paddingAngle={5}
//                           dataKey="value"
//                         >
//                           {expensesData.map((entry, index) => (
//                             <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                           ))}
//                         </Pie>
//                         <Tooltip />
//                       </PieChart>
//                     </ResponsiveContainer>
//                   </Paper>
//                 </Grid>
//               )}
//             </Grid>
//           </Container>
//         </Box>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;


import React, { useState, useContext, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import UserContext from '../UserContext';
import {
  AppBar, Toolbar, Typography, Container, Grid, Paper, Box, Tab, Tabs, IconButton,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider
} from '@mui/material';
import {
  BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, PieChart, Pie, Cell, ResponsiveContainer
} from 'recharts';
import api from '../api';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import LoadingScreen from '../components/LoadingScreen';

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { user } = useContext(UserContext);
  const [stats, setStats] = useState({});
  const [monthlyRevenueData, setMonthlyRevenueData] = useState([]);
  const [occupancyData, setOccupancyData] = useState([]);
  const [expensesData, setExpensesData] = useState([]);
  const [listingsData, setListingsData] = useState([]);
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setError(null);
        console.log("Fetching data for user ID:", user.id);
        const response = await api.get(`/api/reservationstat/${user.id}`);
        if (response.status === 200) {
          console.log("Raw data received:", response.data);
          calculateStats(response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user.id]);

  const calculateStats = (listingsData) => {
    console.log("Calculating stats from:", listingsData);
    let totalIncome = 0;
    let totalExpenses = 0;
    let totalOccupancy = 0;
    let listingCount = 0;
    const monthlyRevenue = {};
    const monthlyOccupancy = {};
    const expenseCategories = {};
    const listingsInfo = [];
  
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    months.forEach(month => {
      monthlyRevenue[month] = 0;
      monthlyOccupancy[month] = 0;
    });
  
    Object.entries(listingsData).forEach(([listingId, reservations]) => {
      listingCount++;
      let listingIncome = 0;
      let listingExpenses = 0;
      let listingOccupancy = 0;
      const listingMonthlyOccupancy = {...monthlyOccupancy};
      const listingMonthlyIncome = {...monthlyRevenue};
      let totalNights = 0;
  
      Object.values(reservations).forEach(reservation => {
        const checkInDate = new Date(reservation.checkIn);
        const checkOutDate = new Date(reservation.checkOut);
        const currentYear = new Date().getFullYear();
        const month = checkInDate.toLocaleString('default', { month: 'short' });
  
        if (checkInDate.getFullYear() === currentYear) {
          const income = reservation.money.fareAccommodationAdjusted || 0;
          const expenses = (reservation.money.totalFees || 0) + 
                           (reservation.money.commission || 0) + 
                           (reservation.money.hostServiceFee || 0);
          
          const nightsCount = (checkOutDate - checkInDate) / (1000 * 60 * 60 * 24);
  
          totalIncome += income;
          totalExpenses += expenses;
          totalOccupancy += nightsCount;
  
          listingIncome += income;
          listingExpenses += expenses;
          listingOccupancy += nightsCount;
  
          monthlyRevenue[month] += income;
          monthlyOccupancy[month] += nightsCount;
          listingMonthlyOccupancy[month] += nightsCount;
          listingMonthlyIncome[month] += income;
  
          totalNights += nightsCount;
  
          expenseCategories['Cleaning'] = (expenseCategories['Cleaning'] || 0) + (reservation.money.fareCleaning || 0);
          expenseCategories['Commission'] = (expenseCategories['Commission'] || 0) + (reservation.money.commission || 0);
          expenseCategories['Host Service Fee'] = (expenseCategories['Host Service Fee'] || 0) + (reservation.money.hostServiceFee || 0);
        }
      });
  
      const listingName = reservations[Object.keys(reservations)[0]]?.listing?.title || `Listing ${listingId}`;
      
      const totalDaysInYear = 365;
      const occupancyRate = (totalNights / totalDaysInYear) * 100;
      
      listingsInfo.push({
        id: listingId,
        name: listingName,
        income: listingIncome,
        expenses: listingExpenses,
        occupancy: occupancyRate,
        opsExpenseRatio: (listingExpenses / listingIncome) * 100,
        monthlyOccupancy: listingMonthlyOccupancy,
        monthlyIncome: listingMonthlyIncome,
        totalNights: totalNights,
        adr: totalNights > 0 ? listingIncome / totalNights : 0
      });
  
      console.log(`Listing ${listingName}:`, {
        income: listingIncome.toFixed(2),
        expenses: listingExpenses.toFixed(2),
        occupancy: occupancyRate.toFixed(2) + '%',
        opsExpenseRatio: ((listingExpenses / listingIncome) * 100).toFixed(2) + '%',
        totalNights: totalNights,
        adr: totalNights > 0 ? (listingIncome / totalNights).toFixed(2) : 'N/A'
      });
    });
  
    const avgOccupancy = listingCount > 0 ? (totalOccupancy / (listingCount * 365)) * 100 : 0;
  
    console.log("Calculated stats:", {
      totalIncome,
      totalExpenses,
      avgOccupancy,
      opsExpenseRatio: totalIncome > 0 ? (totalExpenses / totalIncome) * 100 : 0,
      listingCount,
      totalOccupancy
    });
  
    setStats({
      totalIncome: totalIncome || 0,
      totalExpenses: totalExpenses || 0,
      avgOccupancy: avgOccupancy || 0,
      opsExpenseRatio: totalIncome > 0 ? (totalExpenses / totalIncome) * 100 : 0,
      listingCount: listingCount || 0
    });
  
    const sortedMonthlyRevenue = months.map(month => ({
      name: month,
      value: monthlyRevenue[month]
    }));
  
    setMonthlyRevenueData(sortedMonthlyRevenue || []);
    setOccupancyData(months.map(month => ({ 
      name: month, 
      value: (monthlyOccupancy[month] / (listingCount * getDaysInMonth(month))) * 100 
    })) || []);
    setExpensesData(Object.entries(expenseCategories).map(([name, value]) => ({ name, value })) || []);
    setListingsData(listingsInfo || []);
  
    console.log("Monthly Revenue Data:", sortedMonthlyRevenue);
    console.log("Occupancy Data:", occupancyData);
    console.log("Expenses Data:", expensesData);
    console.log("Listings Data:", listingsInfo);
  };
  
  const getDaysInMonth = (monthName) => {
    const monthIndex = new Date(Date.parse(monthName + " 1, 2000")).getMonth();
    return new Date(new Date().getFullYear(), monthIndex + 1, 0).getDate();
  };

  const handleToggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const handleChange = (event, newValue) => setValue(newValue);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <div className="dashboard">
      <Header onToggleSidebar={handleToggleSidebar} />
      <Sidebar />
      <div className="main-content">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" color="default" elevation={0} sx={{ backgroundColor: 'red', color: 'white' }}>
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                BETA VERSION - MAY INCLUDE FALSE DATA 
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton size="small">
                  <ArrowBack />
                </IconButton>
                <Typography variant="body1" sx={{ mx: 1 }}>
                  {new Date().getFullYear()}
                </Typography>
                <IconButton size="small">
                  <ArrowForward />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          <Container maxWidth="xl" sx={{ mt: 4 }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Overview" />
              <Tab label="Reviews" />
            </Tabs>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={3} sx={{ mt: 2 }}>
              <Grid item xs={12} md={3}>
                <Paper elevation={2} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Ops Expense Ratio
                  </Typography>
                  <Typography variant="h4">{stats.opsExpenseRatio?.toFixed(2)}%</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={3}>
                <Paper elevation={2} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Avg Occupancy
                  </Typography>
                  <Typography variant="h4">{stats.avgOccupancy?.toFixed(2)}%</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={3}>
                <Paper elevation={2} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Total Income YTD
                  </Typography>
                  <Typography variant="h4">€{stats.totalIncome?.toFixed(2)}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={3}>
                <Paper elevation={2} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Total Expenses YTD
                  </Typography>
                  <Typography variant="h4">€{stats.totalExpenses?.toFixed(2)}</Typography>
                </Paper>
              </Grid>
            </Grid>
            <Divider sx={{ my: 3 }} />
            <Grid container spacing={3} sx={{ mt: 2 }}>
              <Grid item xs={12} md={4}>
                <Paper elevation={2} sx={{ p: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Number of Listings
                  </Typography>
                  <Typography variant="h4">{stats.listingCount}</Typography>
                </Paper>
              </Grid>
              {monthlyRevenueData.length > 0 && (
                <Grid item xs={12} md={8}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Monthly Revenue
                    </Typography>
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart data={monthlyRevenueData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="value" fill="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Paper>
                </Grid>
              )}
            </Grid>
            <Divider sx={{ my: 3 }} />
            {listingsData.length > 0 && (
              <Grid container spacing={3} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <TableContainer component={Paper} elevation={2}>
                    <Table sx={{ minWidth: 650 }} aria-label="listings table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Listing Name</TableCell>
                          <TableCell align="right">Income</TableCell>
                          <TableCell align="right">Expenses</TableCell>
                          <TableCell align="right">Occupancy</TableCell>
                          <TableCell align="right">Ops Expense Ratio</TableCell>
                          <TableCell align="right">Total Nights</TableCell>
                          <TableCell align="right">ADR</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {listingsData.map((listing) => (
                          <TableRow key={listing.id}>
                            <TableCell component="th" scope="row">
                              {listing.name}
                            </TableCell>
                            <TableCell align="right">€{listing.income.toFixed(2)}</TableCell>
                            <TableCell align="right">€{listing.expenses.toFixed(2)}</TableCell>
                            <TableCell align="right">{listing.occupancy.toFixed(2)}%</TableCell>
                            <TableCell align="right">{listing.opsExpenseRatio.toFixed(2)}%</TableCell>
                            <TableCell align="right">{listing.totalNights}</TableCell>
                            <TableCell align="right">€{listing.adr.toFixed(2)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            )}
            <Divider sx={{ my: 3 }} />
            <Grid container spacing={3} sx={{ mt: 2 }}>
              {occupancyData.length > 0 && (
                <Grid item xs={12} md={6}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Occupancy
                    </Typography>
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart data={occupancyData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Line type="monotone" dataKey="value" stroke="#8884d8" />
                      </LineChart>
                    </ResponsiveContainer>
                  </Paper>
                </Grid>
              )}
              {expensesData.length > 0 && (
                <Grid item xs={12} md={6}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Expenses Sources
                    </Typography>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie
                          data={expensesData}
                          cx="50%"
                          cy="50%"
                          innerRadius={60}
                          outerRadius={80}
                          fill="#8884d8"
                          paddingAngle={5}
                          dataKey="value"
                        >
                          {expensesData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Container>
        </Box>
      </div>
    </div>
  );
};

export default Dashboard;

