// PhotoGallery.js
import React, { useState } from 'react';
import { Box, Grid, Modal, IconButton } from '@mui/material';
import { Close, NavigateBefore, NavigateNext } from '@mui/icons-material';

const PhotoGallery = ({ pictures }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openModal = (index) => {
    setSelectedImage(pictures[index].original);
    setCurrentIndex(index);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? pictures.length - 1 : prevIndex - 1));
    setSelectedImage(pictures[currentIndex - 1].original);
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === pictures.length - 1 ? 0 : prevIndex + 1));
    setSelectedImage(pictures[currentIndex + 1].original);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        {pictures.map((picture, index) => (
          <Grid item xs={12} sm={6} md={4} key={picture._id}>
            <Box
              component="img"
              src={picture.original}
              alt={`Property Image ${index + 1}`}
              sx={{
                width: '100%',
                height: 'auto',
                cursor: 'pointer',
                borderRadius: '4px',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
              onClick={() => openModal(index)}
            />
          </Grid>
        ))}
      </Grid>
      <Modal open={selectedImage !== null} onClose={closeModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            maxWidth: '90%',
            maxHeight: '90%',
            outline: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: 8, right: 8 }}
            onClick={closeModal}
          >
            <Close />
          </IconButton>
          <Box
            component="img"
            src={selectedImage}
            alt="Selected Property Image"
            sx={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <IconButton onClick={goToPrevious} disabled={currentIndex === 0}>
              <NavigateBefore />
            </IconButton>
            <IconButton onClick={goToNext} disabled={currentIndex === pictures.length - 1}>
              <NavigateNext />
            </IconButton>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default PhotoGallery;