

import React, { useContext, useState, useMemo,useEffect } from 'react';
import { Box, Typography, TextField, Select, MenuItem, Grid, Card, CardContent, CardActions, Button, CircularProgress, LinearProgress,Switch } from '@mui/material';
import { Search as SearchIcon, House as HouseIcon, LocationOn as LocationOnIcon, Help as HelpIcon } from '@mui/icons-material';
import UserContext from '../UserContext';
import { useNavigate } from 'react-router-dom';
import api from '../api';

const ListingsComponent = () => {
  const { listings, isAdmin, user } = useContext(UserContext);
  const navigate = useNavigate();
  const temp =50

  const [selectedRegion, setSelectedRegion] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const [listingsToUpdate, setListingsToUpdate] = useState({});

  useEffect(() => {
    // Fetch the current listingsToUpdate from backend
    const fetchListingsToUpdate = async () => {
      try {
        const response = await api.get('/api/listings-to-update');
        setListingsToUpdate(response.data);
      } catch (error) {
        console.error('Error fetching listings to update:', error);
      }
    };
    fetchListingsToUpdate();
  }, []);

  const handleSwitchChange = async (listingId, isChecked) => {
    try {
      await api.post('/api/update-listing-to-update', { listingId, isChecked });
      setListingsToUpdate(prev => ({...prev, [listingId]: isChecked}));
    } catch (error) {
      console.error('Error updating listing to update:', error);
    }
  };



  

  // const regions = useMemo(() => {
  //   const uniqueRegions = new Set(listings.map(listing => listing.address.city || 'Unknown Region'));
  //   return ['All Regions', ...Array.from(uniqueRegions)];
  // }, [listings]);

  const regions = useMemo(() => {
    if (!Array.isArray(listings)) {
      return ['All Regions'];
    }
  
    const uniqueRegions = new Set(
      listings.map(listing => (listing.address && listing.address.city) || 'Unknown Region')
    );
  
    return ['All Regions', ...Array.from(uniqueRegions)];
  }, [listings]);



  const calculateBookedPercentage = (calendarData, days) => {
  if (!calendarData || calendarData.length === 0) return 0;
  const today = new Date();
  const endDate = new Date(today.getTime() + days * 24 * 60 * 60 * 1000);
  
  const relevantDays = calendarData.filter(day => {
    const dayDate = new Date(day.date);
    return dayDate >= today && dayDate <= endDate;
  });

  const bookedDays = relevantDays.filter(day => !day.available).length;
  return Math.round((bookedDays / days) * 100);
};

  const { filteredListings, duplicateListings } = useMemo(() => {
    //let filtered = isAdmin ? listings : listings.filter(listing => listing.owners.includes(user.id));



    let filtered = listings || []
    console.log(filtered)
  
    // if (selectedRegion && selectedRegion !== 'All Regions') {
    //   filtered = filtered.filter(listing => listing.address.city === selectedRegion);
    // }
    if (selectedRegion && selectedRegion !== 'All Regions') {
      filtered = filtered.filter(listing => listing.address && listing.address.city === selectedRegion);
    }
  
    if (searchQuery) {
      const lowercaseQuery = searchQuery.toLowerCase();
      filtered = filtered.filter(listing =>
        Object.values(listing).some(value =>
          String(value).toLowerCase().includes(lowercaseQuery)
        )
      );
    }
  
    const uniqueListings = [];
    const duplicates = [];
  
    const idSet = new Set();
  
    filtered.forEach(listing => {
      if (idSet.has(listing._id)) {
        duplicates.push(listing);
      } else {
        idSet.add(listing._id);
        uniqueListings.push(listing);
      }
    });
  
    return { filteredListings: uniqueListings, duplicateListings: duplicates };
  }, [listings, isAdmin, user.id, selectedRegion, searchQuery]);

  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const calculateOccupancy = (occupancyStats, days) => {
    if (!occupancyStats || occupancyStats.length === 0) return 0;
    const filteredStats = occupancyStats.filter(stat => stat.days <= days);
    if (filteredStats.length === 0) return 0;

    const totalOccupancy = filteredStats.reduce((sum, stat) => sum + stat.occupancy, 0);
    return Math.round(totalOccupancy / filteredStats.length);
  };




  const handleViewListing = (listingId) => {
    navigate(`/listings/${listingId}`);
  };

  if (!listings) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="200px">
        <CircularProgress />
      </Box>
    );
  }


  return (
    <Box p={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <TextField
          placeholder="Search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
        />
        <Select variant="outlined" size="small" value={selectedRegion} onChange={handleRegionChange}>
          {regions.map((region, index) => (
            <MenuItem key={index} value={region}>{region}</MenuItem>
          ))}
        </Select>
      </Box>
      <Grid container spacing={3}>
        {filteredListings.map(listing => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={listing._id}>

            <Card>
              <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                  <Box display="flex" alignItems="center">
                    <HouseIcon color="primary" />
                    <Typography variant="subtitle1" ml={1}>{listing.title}</Typography>
                  </Box>
                </Box>
                {listing.nickname && listing.nickname.trim() !== '' && (
                <Box display="flex" alignItems="center" mb={1}>
                  <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
                    🏷️ <span style={{ marginLeft: '4px' }}>{listing.nickname}</span>
                  </Typography>
                </Box>
              )}
                <Box position="relative">


                  
                  <img src={listing.picture?.thumbnail || listing.picture?.large} alt={listing.title} style={{ width: '100%', height: 200, objectFit: 'cover' }} />
                </Box>
                <Box display="flex" alignItems="center" mt={2}>
                  <LocationOnIcon fontSize="small" />
                  <Typography variant="caption" ml={1}>
                    {listing.address ? `${listing.address.city || 'Unknown City'} ${listing.address.country || 'Unknown Country'}` : 'Address not available'}
                  </Typography>
                </Box>

                <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Box position="relative" display="inline-flex" alignItems="center" justifyContent="center" width={60} height={60}>
                      <CircularProgress
                        variant="determinate"
                        value={100}
                        size={60}
                        thickness={4}
                        sx={{
                          color: 'grey.300',
                          position: 'absolute',
                        }}
                      />
                      <CircularProgress
                        variant="determinate"
                        value={listing.available_14_days}
                        size={60}
                        thickness={4}
                        sx={{
                          color: 'primary.main',
                          position: 'absolute',
                        }}
                      />
                      <Box
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="caption" component="div" color="text.secondary">
                          {listing.available_14_days}%
                        </Typography>
                      </Box>
                    </Box>
                    <Typography variant="caption" color="textSecondary" align="center" mt={1}>
                      Next 14 days
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Box position="relative" display="inline-flex" alignItems="center" justifyContent="center" width={60} height={60}>
                      <CircularProgress
                        variant="determinate"
                        value={100}
                        size={60}
                        thickness={4}
                        sx={{
                          color: 'grey.300',
                          position: 'absolute',
                        }}
                      />
                      <CircularProgress
                        variant="determinate"
                        value={listing.available_30_days}
                        size={60}
                        thickness={4}
                        sx={{
                          color: 'primary.main',
                          position: 'absolute',
                        }}
                      />
                      <Box
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="caption" component="div" color="text.secondary">
                          {listing.available_30_days}%
                        </Typography>
                      </Box>
                    </Box>
                    <Typography variant="caption" color="textSecondary" align="center" mt={1}>
                      Next 30 days
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Box position="relative" display="inline-flex" alignItems="center" justifyContent="center" width={60} height={60}>
                      <CircularProgress
                        variant="determinate"
                        value={100}
                        size={60}
                        thickness={4}
                        sx={{
                          color: 'grey.300',
                          position: 'absolute',
                        }}
                      />
                      <CircularProgress
                        variant="determinate"
                        value={listing.available_90_days}
                        size={60}
                        thickness={4}
                        sx={{
                          color: 'primary.main',
                          position: 'absolute',
                        }}
                      />
                      <Box
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="caption" component="div" color="text.secondary">
                          {listing.available_90_days}%
                        </Typography>
                      </Box>
                    </Box>
                    <Typography variant="caption" color="textSecondary" align="center" mt={1}>
                      Next 90 days
                    </Typography>
                  </Box>
                </Grid>
            </Grid>
          </Box>


                {/* <Box mt={2}>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography variant="caption" color="textSecondary">Score</Typography>
                    <HelpIcon fontSize="small" color="disabled" style={{ marginLeft: 4 }} />
                  </Box>
                  <Typography variant="caption">Your Listing Performance</Typography>
                  <Box display="flex" alignItems="center" mt={1}>
                    <Box flexGrow={1} mr={1}>
                      <LinearProgress variant="determinate" value={temp} />
                    </Box>
                    <Typography variant="caption">{temp}%</Typography>
                  </Box>
                </Box> */}
              </CardContent>
              <CardActions>
                <Grid container spacing={1}>

                  <Grid item xs={15}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    onClick={() => handleViewListing(listing._id)}
                  >
                    View
                  </Button>
                  </Grid>
                </Grid>
              </CardActions>
              {isAdmin && (
                <CardActions>
                  <Typography variant="body2">Update nightly:</Typography>
                  <Switch
                    checked={!!listingsToUpdate[listing._id]}
                    onChange={(e) => handleSwitchChange(listing._id, e.target.checked)}
                    color="primary"
                  />
                </CardActions>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ListingsComponent;