import React, { useContext } from 'react';
import { Box } from '@mui/material';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ListingsComponent from '../components/ListingsComponent';
import listings_dummy from '../data/dummyData';
import UserContext from '../UserContext';

const ListingsPage = () => {
  const { user ,isAdmin, listings} = useContext(UserContext);
  console.log(listings)

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header />
      <Box display="flex" flexGrow={1}>
        <Sidebar />
        <Box flexGrow={1} p={3}>
          <ListingsComponent />
        </Box>
      </Box>
    </Box>
  );
};

export default ListingsPage;